<template>
	<div id="topDiv" >
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Create Booking</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/my-booking">My booking</b-breadcrumb-item>
					<b-breadcrumb-item active>Add </b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
		<br>
		<b-form @submit.prevent="handleProceedBooking">
			<b-row>
				<b-col lg="8" md="8" sm="12" class="heading-title " ref="msgContainer">
					<div v-if="this.show_booking_summary == false">
						
						<b-card class="mb-4 w-100">
							<h3 class="text-center  mt-2">Booking Form</h3>
							<div class="d-flex justify-content-between">
								
								<div> 
									Order date : <span class="text-danger fs-4">{{ date_format(this.today_date) }}</span>
								</div>
								<div> 
									Form ID. <span class="text-danger fs-4">{{ Book_id }}</span>
								</div>
							</div>
							<hr>
							<b-row class="mb-3">
								<b-col md="6">
									<b-row class=" align-items-baseline">
										<b-col sm="12" class="text-left">
											<label class="fw-medium mb-0 custtom-venue-label" for="nname2"
											>Venue<span class="text-danger">*</span> :</label>
										</b-col>
										<b-col sm="12">
											
											<b-form-select
											v-model="$v.form.child_selected.$model"
											:options="child_option"
											placeholder="Select Child Venue"
											track-by="value"
											label="text"
											id="child_selected"
											@change="getChildVenueDetails"
											:state="validateState('child_selected')"
											class="styledSelect"
											>
											</b-form-select>
											<b-form-invalid-feedback
											id="pehrs-1-live-feedback"
											v-if="$v.form.child_selected.$error"
											>
												<div v-if="!$v.form.child_selected.required">
													Please select child venue
												</div>
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-col>
								<!-- <b-col md="2"></b-col> -->
								<b-col md="6">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2"
									>Event Date<span class="text-danger">*</span> :</label
									>
									<b-form-group>
										<div class="w-100">
											<b-form-datepicker
											id="start_booking_date"
											placeholder="Start date"
											v-model="$v.form.start_booking_date.$model"
											class="mb-2"
											:min="today_date"
											:date-format-options="{
											year: 'numeric',
											month: 'short',
											day: '2-digit',
											}"
											:state="validateState('start_booking_date')"
											@input="gettimenew()"
											></b-form-datepicker>
											<b-form-invalid-feedback
											id="pehrs-1-live-feedback"
											v-if="$v.form.start_booking_date.$error"
											>
												<div v-if="!$v.form.start_booking_date.required">
													Please select start booking date
												</div>
											</b-form-invalid-feedback>
										</div>
									</b-form-group>
								</b-col>
								
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2"
									>Shifts<span class="text-danger">*</span> :</label
									>
								
									<select :class="{'form-control':true,[form.shift!='' ? 'is-valid':'']:true}"  :state="validateState('shift')" v-model="$v.form.shift.$model" @change="gettimenew">
										<option value="">Select Shift</option>
										<option v-for="(shift,sh) in newChild_shift_time" :key="sh" :value="changeShiftId(shift.name)">{{ shift.name }} Shift</option>
									</select>
									
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.shift.$error"
									>
										<div v-if="!$v.form.shift.required">
											Please select your shift
										</div>
									</b-form-invalid-feedback>
									<span v-if="ava_meaasge">
										<div :class="ava_color">{{ava_meaasge}}</div>
									</span>
								</b-col>
								<!-- <b-col sm="2"></b-col> -->
								<b-col sm="3" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2"
									>Time<span class="text-danger">*</span> :</label
									>
									<input type="time" id="appt" v-model="$v.form.stime.$model" 
									:class="{'form-control':true,[form.stime!='' ? 'is-valid':'']:true}" 
									name="appt" :min="form.org_stime" :max="form.org_etime" :state="isValidTime1(form.stime,form.etime)" required>
						
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.stime.$error"
									>
										<div v-if="!$v.form.stime.required">
											Please select start shift time
										</div>
									</b-form-invalid-feedback>
									<b-spinner small label="" class="mt-2"  v-if="checking_avaliblity"></b-spinner> <span v-if="checking_avaliblity">Checking...</span>
									
								</b-col>
								<b-col sm="3" class="text-left mt-2" >
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2"
									>Time<span class="text-danger">*</span> :</label
									>
								
									<input type="time" id="appt" 
									v-model="$v.form.etime.$model" 
									:class="{'form-control':true,[form.etime!='' ? 'is-valid':'']:true}"  
									name="appt" :state="isValidTime(form.stime,form.etime)" 
									:min="form.stime" 
									:max="form.org_etime" required>
									<b-form-invalid-feedback
									id="pehrs-1-live-feedback"
									v-if="$v.form.etime.$error"
									>
										<div v-if="!$v.form.etime.required">
											Please select end shift time
										</div>
									</b-form-invalid-feedback>
								</b-col>
								<b-col sm="6" class="text-left mt-3">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Event type<span class="text-danger">*</span> :</label>
									<b-form-group>
										<b-form-select
										id="event"
										v-model="$v.form.event.$model"
										:options="event_types"
										:close-on-select="false"
										:state="validateState('event')"
										placeholder="Event type "
										track-by="text"
										label="text"
										@change="CheckEventType()"
										>
										</b-form-select>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.form.event.$error"
										>
											<div v-if="!$v.form.event.required">
												Please select venue
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
							
								<b-col sm="3" class="text-left mt-3">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Guest Capacity<span class="text-danger">*</span> :</label>
									<b-input-group  class="mb-2">
										<b-form-input
										id="gustcapa"
										v-model="$v.form.gustcapa.$model"
										:state="validateState('gustcapa')"
										placeholder="Enter Guest Capacity "
										label="text"
										@input="checkGuestCapcity"
										v-b-tooltip.hover title="Enter Guest Capacity"
										>
										</b-form-input>
										
										<b-form-input
										v-b-tooltip.hover title="Guest Capacity"
										id="gustcapa_"
										v-model="form.guest_rooms"
										placeholder="Guest Capacity "
										label="text"
										readonly
										class="back-color"
										>
										</b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.form.event.$error"
										>
											<div v-if="!$v.form.event.required">
												Please select venue
											</div>
										</b-form-invalid-feedback>
									</b-input-group>
									<input type="checkbox" v-model="form.CustomguestCapacity"> Floating Capacity
									
								
									
								</b-col>
								<b-col sm="3" class="text-left mt-3">
									<div class="" v-if="suggestions.length>0">
										<span  v-if="suggestions.length>0" class="mt-2 text-primary">New Suggestion:</span>
										<div class="" v-for="(sugg,i) in  suggestions" :key="i">
											{{ sugg.child_venue_name }} Capacity :  {{sugg.guest_rooms }} <span class="text-success cursor-pointer" @click="suggest_getChildVenueDetails(sugg.child_venue_id)">Availiable</span>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-card>
						
						<b-card class="mb-0 w-100 ">
							<h4 class="mt-3">Customer details</h4>
							<hr>
							<div class="flex justify-content-start mb-2 ">
								<div class="fw-medium">
									Are you booking for yourself?
								</div>
								<div> 
									<b-form-group >
										<b-form-radio-group
										id="radiogroup2"
										
										v-model="form.booking_for_self"
										>
											<b-form-radio value="yes"  >Yes</b-form-radio>
											<b-form-radio value="no" >No</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									
								</div>
							</div>
							
							
							<b-row class="py-3  align-items-center bg-custom-gray" v-if="this.form.booking_for_self == 'no'">
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Representative Name <span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.booking_for_name"
									id="booking_for_relationship"
									type="text"
									placeholder="Enter Name"
									
									></b-form-input>
								</b-col>
								
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Relationship<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.booking_for_relationship"
									id="booking_for_relationship"
									type="text"
									placeholder="Enter Relationship"
									></b-form-input>
								</b-col>
								
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Mobile No<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="$v.form.booking_for_mobile_no.$model"
									id="booking_for_mobile_no"
									type="text"
									placeholder="Enter Mobile no"
									@keyup="CheckPhonenumber($event)"
									:state="validateState('booking_for_mobile_no')"
									></b-form-input>
									<b-form-invalid-feedback id="pehrs-1-live-feedback">
										
										<div
										v-if="
										!$v.form.booking_for_mobile_no.minLength ||
										!$v.form.booking_for_mobile_no.numeric ||
										!$v.form.booking_for_mobile_no.maxLength
										"
										>
											Please enter valid Contact
										</div>
									</b-form-invalid-feedback>
								</b-col>
							
							</b-row>
							<b-row  class="py-3 align-items-center">
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name<span class="text-danger">*</span> :</label>
									<b-form-group>
										<b-form-input
										v-model="$v.form.name.$model"
										id="name"
										type="text"
										placeholder="Enter Customer Name"
										:state="validateState('name')"
										
										></b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.form.name.$error"
										>
											<div v-if="!$v.form.name.required">
												Please enter your Customer Name
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Email<span class="text-danger">*</span> :</label>
									<b-form-group>
										<b-form-input
										v-model="$v.form.email.$model"
										id="email"
										type="email"
										placeholder="Enter Customer Email"
										:state="validateState('email')"
										@blur="checkemail_avaliblity"
										></b-form-input>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.form.email.$error"
										>
											<div v-if="!$v.form.email.required">
												Please enter your Email
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Phone No<span class="text-danger">*</span> :</label>
									<b-form-group>
										<b-form-input
										v-model="$v.form.contact.$model"
										name="contact"
										type="text"
										:state="validateState('contact')"
										placeholder="Enter Customer Contact No."
										@keyup="CheckPhonenumber($event)"
										></b-form-input>
										<b-form-invalid-feedback id="pehrs-1-live-feedback">
											<div v-if="!$v.form.contact.required">
												Please enter your Contact
											</div>
											<div
											v-if="
											!$v.form.contact.minLength ||
											!$v.form.contact.numeric ||
											!$v.form.contact.maxLength
											"
											>
												Please enter valid Contact
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
									
								</b-col>
								
								<b-col sm="6" class="text-left mt-2">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Alternative Mobile No :</label>
									<b-form-group>
										<b-form-input
										v-model="$v.form.Acontact.$model"
										name="contact"
										type="text"
										:state="validateState('Acontact')"
										placeholder="Enter Customer Alternative Contact No."
										@keyup="CheckPhonenumber($event)"
										></b-form-input>
										<b-form-invalid-feedback id="pehrs-1-live-feedback">
											
											<div
											v-if="
											!$v.form.Acontact.minLength ||
											!$v.form.Acontact.numeric ||
											!$v.form.Acontact.maxLength
											"
											>
												Please enter valid Contact
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
									
								</b-col>
							
							</b-row>
							<b-row>
								
								
								<b-col sm="12" class="text-left mt-2  mb-3">
									<span v-if="mobile_message">
										<div class="alert alert-danger alert-sm">Mobile number Same.</div>
									</span>
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Address<span class="text-danger">*</span> :</label>
									<b-form-group>
										<b-form-textarea
										v-model="$v.form.address.$model"
										id="address"
										type="text"
										:state="validateState('address')"
										placeholder="Enter your Address"
										></b-form-textarea>
										<b-form-invalid-feedback
										id="pehrs-1-live-feedback"
										v-if="$v.form.address.$error"
										>
											<div v-if="!$v.form.address.required">
												Please enter your address
											</div>
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								
							</b-row>
							<b-row>
								
							</b-row>
						</b-card>
						<b-card class="mb-0 w-100 mt-4 " v-if="OnlyWedding">
							<h4 class="mt-3">Event Details </h4>
							<hr>
							
							
							<b-row class="py-3 align-items-center  mb-3" >
								
								<b-col sm="6" class="text-left">
									<h5>Bride</h5>
									<hr class="underline_color">
									<label class="fw-medium mb-2 custtom-venue-label" for="uname2">Name:</label>
									<b-form-input
									v-model="form.bride"
									id="bride"
									type="text"
									placeholder="Enter Name of the Bride"
									></b-form-input>
									
									<label class="fw-medium mb-2 custtom-venue-label" for="uname2">Mobile number :</label>
									<b-form-input
									v-model="form.bride_mob"
									id="bridelocation"
									type="text"
									placeholder="Enter The Phone number of Bride"
									></b-form-input>
									
									<label class="fw-medium mb-2 custtom-venue-label" for="uname2">Place :</label>
									<b-form-input
									v-model="form.bridelocation"
									id="bridelocation"
									type="text"
									placeholder="Enter Place of Bride"
									></b-form-input>
									
									
								</b-col>
								
								<b-col sm="6" class="text-left ">
									<h5>Groom</h5>
									<hr class="underline_color">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name :</label>
									<b-form-input
									v-model="form.bridegroom" 
									id="Bbridegroom"
									type="text"
									placeholder="Enter Name of the Bridegroom"
									></b-form-input>
									
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Mobile Number :</label>
									<b-form-input
									v-model="form.groom_mob"
									id="bridegroomlocation" 
									type="text"
									placeholder="Enter Mobile number of the Bridegroom"
									></b-form-input>
									
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Place:</label>
									<b-form-input
									v-model="form.bridegroomlocation"
									id="bridegroomlocation"
									type="text"
									placeholder="Enter Place of the Bridegroom"
									></b-form-input>
									
								</b-col>
								
								
							</b-row>
						</b-card>
						<b-card class="mb-0 w-100 mt-4 ">
							<h4 class="mt-3">Service Provider Details </h4>
							<hr>
							<b-row class="py-3 align-items-center  mb-3" >
								<b-col sm="6" class="text-left ">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name of the Caterer :</label>
									<b-form-input
									v-model="form.caterer"
									id="caterer"
									type="text"
									placeholder="Enter Name of the Caterer"
									></b-form-input>
								</b-col>
								<b-col sm="6" class="text-left ">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name of the Decorator :</label>
									<b-form-input
									v-model="form.decorator"
									id="decorator"
									type="text"
									placeholder="Enter Name of the Decorator"
									></b-form-input>
								</b-col> 
								<b-col sm="6" class="text-left  ">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name of the Sound system person :</label>
									<b-form-input
									v-model="form.sound"
									id="sound"
									type="text"
									placeholder="Enter Name of the Sound system person"
									></b-form-input>
								</b-col>
								<b-col sm="6" class="text-left mt-3 ">
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Name Of the Music/Dance troop :</label>
									<b-form-input
									v-model="form.music"
									id="music"
									type="text"
									placeholder="Enter Name Of the Music/Dance troop"
									></b-form-input>
								</b-col>
							</b-row>
							
							
							
						</b-card>
						<b-card class="mb-4 w-100 mt-4">
							<h4 class="mt-3">Pricing</h4>
							<hr>
							<div class="row mb-3">
								<div class="col-md-6">
									<h5>Base price: <i :class="{'mdi':true,[curncy]:true}"></i> {{ Indian_numberWithCommas(this.childvenue.shift_price) }}</h5>
									<br>
								
								</div>
								<div class="col-md-6"  v-if="json_convert(childvenue.settings.all_settings).allow_security==true">
									<h5 class="mb--5">Security Deposit: <i :class="{'mdi':true,[curncy]:true}" ></i>{{ Indian_numberWithCommas(json_convert(childvenue.settings.all_settings).security_deposit || 0) }}</h5>
									<i class="fs-12 text-danger" v-if="json_convert(childvenue.settings.all_settings).security_refund ? true:false">(Refundable Deposit)</i>
									
								</div>
								<div class="col-md-6"  v-else>
									<h5 class="mb--5">Security Deposit: <i :class="{'mdi':true,[curncy]:true}" ></i>0</h5>
									
								</div>
							</div>
							<br>
							<h4 class="mt-3">Add-Ons</h4>
							<hr>
							
							<div class="mt-3" v-for="(add_cat,i) in childvenue.add_on_categories" :key="i">
								<b-button block v-b-toggle="'accordion-'+i" class="btn-venuecolor" >{{ add_cat.name }}</b-button>
								<b-collapse :id="'accordion-'+i" visible accordion="my-accordion" role="tabpanel">
									
									<div class="row mt-3">
										<div class="col-md-4 col-xl-4 mb-3" v-for="(addon, index) in childvenue.addOns_cat[add_cat.name]" :key="index">
											
											
											<div class="extradesign">
												<div class="card h-100">
													<div class="bg-label-primary rounded-3 text-center mb-0 pt-0">
														<img class="img-fluid fix-set" :src="addon.attachment ? path + JSON.parse(addon.attachment) : require('../../assets/images/addon.png') " alt="Card girl image" width="500">
													</div>
													<div class="mb-0 text-center">
														<span  v-if="addon.add_on_name.length<15" class="text-center">{{ addon.add_on_name }}</span>
														<span  class="text-center" v-else>{{ addon.add_on_name.substring(0,15)+".." }}</span> <br>
														<p> <i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.price) }} / {{ addon.type }} <span class="text-success" v-if="addon.type == 'unit'"><span v-if="addon.stock >= countInput[addon.auto_increment]"><span  v-if="addon.stock-addon.damagd > 0">Available Stock {{ addon.stock-addon.damagd-countInput[addon.auto_increment] }}</span></span><span v-else >Available Stock {{ addon.stock}}</span></span>  </p>
														
														
														<div class="row mb-2">
															<div class="col-md-6" >
																<span v-if="addon.type=='unit'">
																	<span class="d-none">{{autoData}}</span>
																	<b-input-group>
																		<b-input-group-prepend>
																			<b-button  class="btn btn-venuecolor"  v-on:click="decrement(addon.auto_increment,index)"  :disabled="countInput[addon.auto_increment] <= 0">-</b-button>
																		</b-input-group-prepend>
																		
																		<b-form-input type="number" minlength="0" @click="checkInput(addon.auto_increment)" :maxlength="addon.stock-addon.damagd" :id="'quantity'+addon.auto_increment"  v-model="countInput[addon.auto_increment]" :readonly="countInput[addon.auto_increment]==addon.stock-addon.damagd" :value="countInput[addon.auto_increment]"></b-form-input>
																		
																		<b-input-group-append>
																			<b-button  class="btn btn-venuecolor"  v-on:click="increment( addon.auto_increment,addon.stock-addon.damagd,index)" :disabled="countInput[addon.auto_increment]>=addon.stock-addon.damagd">+</b-button>
																		</b-input-group-append>
																	</b-input-group>
																	
																</span>
															
															</div>
															<div class="col-md-2"></div>
															<div class="col-md-4">
																<span v-if="addon.type=='unit'">
																	<input v-if="addon.stock-addon.damagd > 0 " type="checkbox"  style="display: none;"  :id="addon.add_on_name"    @change="onChange(addon.auto_increment,$event, addon.add_on_name,addon.price,JSON.parse(addon.attachment) ? baseUrl+'/'+JSON.parse(addon.attachment) : require('../../assets/images/addon.png'),addon.add_on_id,addon.type)" v-model="form.selected_add_ons"
																	:value="addon.add_on_id">	
																	<label class="addon-label " :for="addon.add_on_name" >  

																		<!-- <span class="btn btn-block btn-venuecolor" v-if="addon.stock-addon.damagd != 0  && checkarray(addon.add_on_id)==false && countInput[addon.auto_increment]==0" > Add </span>
																		<span class="btn btn-block btn-venuecolor" v-else-if="checkarray(addon.add_on_id)==false && countInput[addon.auto_increment]>0">Add </span>
																		
																		<span class="btn btn-block btn-danger " v-else-if="checkarray(addon.add_on_id)==true && countInput[addon.auto_increment]==0" >Remove </span>
																		<span class="btn btn-block btn-warning " v-else-if="addon.stock-addon.damagd == 0 && checkarray(addon.add_on_id)==false"> No stock </span>
																		<span class="btn btn-block btn-success" v-else-if="checkarray(addon.add_on_id)==true && countInput[addon.auto_increment]>0">Add </span> -->
																		<span class="btn btn-block btn-success" v-if="countInput[addon.auto_increment] > 0" > Add </span>
																		<span class="btn btn-block btn-danger" v-else-if="countInput[addon.auto_increment] == 0 && check_active_item(addon.add_on_id)!='-1'" > Remove </span>
																		<span class="btn btn-block btn-venuecolor" v-else-if="countInput[addon.auto_increment] == 0 && check_active_item(addon.add_on_id)=='-1'" > Add </span>
																		<span class="btn btn-block btn-warning" v-else-if="addon.stock-addon.damagd == 0" > No stock </span>
																	
																	</label>
																	
																</span>
																<span v-else>
																	<input  type="checkbox"  style="display: none;"  :id="addon.add_on_name"    @change="onChange(addon.auto_increment,$event, addon.add_on_name,addon.price,JSON.parse(addon.attachment) ? baseUrl+'/'+JSON.parse(addon.attachment) : require('../../assets/images/addon.png'),addon.add_on_id,addon.type)" v-model="form.selected_add_ons"
																	:value="addon.add_on_id">	
																	<label class="addon-label " :for="addon.add_on_name">  
																		<span class="btn btn-block btn-danger"  v-if="checkarray(addon.add_on_id)==true">Remove </span>
																		<span class="btn btn-block btn-venuecolor"  v-else  > Add</span>
																	</label>
																</span>
															</div>
															
															
															
															
															
														</div>
														
														
														
														
														
														
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</b-collapse>
							</div>
							
							
							
							
							<div class=" mb-2">
								<div class="row">
								
								</div>
							
								
								<b-row>
									
								
								</b-row>
							</div>
							
							
							
						</b-card>
						<b-card :class="{'mb-4 w-100':true,[form.proceed_with_quotation=='yes_' ? 'disabledcard' :'' ]:true}" >
							<h4 class="mt-3">Payment Details</h4>
							<hr>
							<b-row>
								<b-col md="6" v-if="json_convert(childvenue.settings.all_settings).allowbook || json_convert(childvenue.settings.all_settings).allowblock">
									<b-row >
										<b-col md="6"> </b-col>
										<b-col md="6 ">
											
											<b-form-group >
												<b-form-radio-group
												id="radio-group-2"
												
												v-model="form.payment_master_type"
												@change="check_booking_payment()" class="radio">
													<div class="d-flex">
														<b-form-radio class="radio_design" v-model="booked_val" value="booked"  v-if="json_convert(childvenue.settings.all_settings).allowbook" >Booked</b-form-radio>
														<b-form-radio class="radio_design" v-model="booked_val" value="blocked" v-if="json_convert(childvenue.settings.all_settings).allowblock">Blocked</b-form-radio>
													</div>
													
												</b-form-radio-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row class="mt-3">
										<b-col md="6" v-if="this.form.payment_master_type == 'booked'"> Payment</b-col>
										<b-col md="6">
											
											<b-form-group v-if="this.form.payment_master_type == 'booked'">
												<b-form-radio-group
												id="radio-group-3"
												
												v-model="form.payment_booked_type"
												@change="Checkpay_type()">
													<b-form-radio value="full" v-model="booked_type">Full</b-form-radio>
													<b-form-radio value="advance" v-model="booked_type">Advance</b-form-radio>
												</b-form-radio-group>
											</b-form-group> 
										</b-col>
									</b-row>
								</b-col>
								<b-col md="6" v-if="form.payment_master_type == 'blocked'">
									
								</b-col>
							</b-row>
							<br>
							<b-row>
								<b-col md="3">Payment Method: </b-col>
								<b-col md="3">
									
									<b-form-radio-group
									id="radio-group-4"
									
									v-model="form.payment_mode"
									>
										<div class="d-flex">
											<b-form-radio value="cash" >Cash</b-form-radio>
											<b-form-radio value="online" >Online</b-form-radio>
											<b-form-radio value="cheque" >Cheque</b-form-radio>
										</div>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<br>
							<b-row v-if="this.form.payment_mode == 'cheque'">
								<b-col cols="3"></b-col>
								<b-col cols="4" >
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Cheque No<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.cheque_no" placeholder="Enter Cheque no"
									type="text"
									></b-form-input>
								</b-col>
								<b-col cols="4" >
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Bank Name<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.bank_name" placeholder="Enter bank name"
									type="text"
									></b-form-input>
								</b-col>
								
							</b-row>
							
							<b-row v-if="this.form.payment_mode == 'online'">
								<b-col cols="3"></b-col>
								<b-col cols="4" >
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Account Holder name<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.onlinename" placeholder="Enter Account Holder name"
									type="text"
									></b-form-input>
								</b-col>
								<b-col cols="4" >
									<label class="fw-medium mb-0 custtom-venue-label" for="uname2">Bank Name<span class="text-danger">*</span> :</label>
									<b-form-input
									v-model="form.online_bank_name" placeholder="Enter bank name"
									type="text"
									></b-form-input>
								</b-col>
								
							</b-row>
							<br>
							
							<b-row>
								<b-col md="3">Total Amount: </b-col>
								<b-col md="3">
									<b-input-group >
										<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
										<b-form-input
										v-model="full_total_amount"
										type="number"
										placeholder="Booking Amount"
										readonly
										></b-form-input>
									</b-input-group>
								</b-col>
								<b-col md="6"></b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col md="3">Pay Amount: </b-col>
								<b-col md="3">
									
									<b-input-group >
										<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
										<b-form-input
										v-model="form.paid_amount"
										type="number"
										placeholder="Shift price"
										@input="checkamount_validation()"
										></b-form-input>
									</b-input-group>
									<span v-if="errormessage_amount" class="text-danger">{{errormessage_amount}}</span>
								</b-col>
								
							</b-row>
							<br>
							<b-row class="mb-3">
								<b-col md="3">Discount: <br><span class="text-danger fs-12">This only deducted from base price</span></b-col>
								
								<b-col md="3">
									<b-input-group >
										<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
										<b-form-input type="number" :readonly="this.booked_val=='blocked'" v-model="form.applied_discount" placeholder="Enter Discount price" @input="checkDiscount('amount')" @focus="$event.target.select()">
										</b-form-input>
									</b-input-group>
									
									<span v-if="form.applied_discount>this.childvenue.shift_price" class="text-danger">
										Discount amount and Base price is not matching ...
									</span>
									<span v-if="this.booked_val=='blocked'" class="text-danger">Discount and GST not Avaliable for Block and Quoation</span>
									
								</b-col>
								<b-col md="2">
									<b-input-group >
										<b-form-input type="text" max="3" :readonly="this.booked_val=='blocked'" v-model="form.applied_discount_percentage" placeholder="Discount %" @input="checkDiscount('percent')">
										</b-form-input>
										<b-input-group-prepend is-text><b>%</b></b-input-group-prepend>
									</b-input-group>
								</b-col>
								
							</b-row>
							
						</b-card>
						<b-card class="mb-4 w-100">
							<h4 class="mt-3">Special Requests</h4>
							<p>Special requests cannot be guaranteed – but the property will do its best to meet your needs. You can always make a special request after your booking is complete!</p>
							<b-row class="mb-3">
								<b-col cols="12">
									
									<vue-editor v-model="form.special_request" class="venue-custom-input"></vue-editor>
								</b-col>
							</b-row>
						</b-card>
						
						
						
					</div>
				</b-col>
				
				<b-col lg="4" md="4" sm="6"  > <!-- position-fixed overflow-auto --> <!-- ref="headRef" -->
					<div v-if="this.show_booking_summary == false">
						<div class="mb-4 side-container"  ref="side"
						:class="{ 'side-container--sticky': isHeaderSticky }" >
							<b-card class="">
								<h4 class="mr-5 mt-2"> Summary</h4>
								<hr class="underline_color">
								<ul class="custom-amount-summary">
									<li class="mb-3">
										<div class="row">
											<div class="col-md-8"> 
												<b>Base Price</b>
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 fw-bold text-right" > 
												{{ Indian_numberWithCommas(this.childvenue.shift_price )}}
											</div>
										</div>	
										<div class="row">
											<div class="col-md-8" v-if="this.form.start_booking_date">
												<i class="fs-12">On {{ date_format(this.form.start_booking_date) }} {{ this.childvenue.shift_type }} Shift</i>
											</div>
										</div>
									</li>
									<li >
										<b>Add ons :</b> 
									</li>
									<li v-if="activeItem.length==0" >
										<br> 
									</li>
									<li v-for="(addo ,ad) in activeItem" :key="ad">
										<div class="row">
											<div class="col-md-5 fs-12"> 
												{{ addo[0] }} 
											</div>
											<div class="col-md-3 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true}"></i>{{Indian_numberWithCommas(addo[1])}} * {{ addo[3] }}
												
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 fw-bold text-right" > 
												{{ Indian_numberWithCommas(addo[4]) }}
											</div>
										</div>	
									</li>
									
									<li>
										<div class="row mb-2">
											<div class="col-md-8"> 
												<b>Total Add on </b>
											</div>
											
											<div class="col-md-1 fw-bold text-right"> 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 text-right" style="border-top:1px solid black"> 
												
												<b>{{Indian_numberWithCommas(add_amount)}}</b>
											</div>
											
										</div>	
									</li>
									
									<hr class="custom-hr" >
									<li >
										<div class="row mb-2">
											<div class="col-md-8"> 
												<b>Total Base Price</b>
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 text-right" > 
												<b>{{Indian_numberWithCommas(addontotal)}}</b>
											</div>
										</div>	
									</li>
									<!-- v-if="activeItem.length>0"  -->
									<li >
										<div class="row">
											<div class="col-md-8"> 
												Discount ({{ form.applied_discount_percentage }}%)
											</div>
											<div class="col-md-1 fw-bold text-right" v-if="this.form.applied_discount != '' && this.form.applied_discount > 0">
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3  text-right" v-if="this.form.applied_discount != '' && this.form.applied_discount > 0"> 
												<span v-if="form.applied_discount<=addontotal" >
													{{ Indian_numberWithCommas(this.form.applied_discount) }}
												</span>
												<span v-else> 0</span>
											</div>
										</div>	
									</li>
									<!-- v-if="this.form.applied_discount != '' && this.form.applied_discount > 0" -->
									<!-- v-if="this.form.applied_discount != '' && this.form.applied_discount > 0" -->
									<hr class="custom-hr"  >
									<li>
										<div class="row">
											<div class="col-md-8"> 
												<b>Sub total</b>
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div  class="col-md-3 text-right"> 
												<b>{{Indian_numberWithCommas(Math.round(after_discount))}}</b>
											</div>
										</div>	
									</li>
									<li class="mt-2">
										<div class="row">
											<div class="col-md-8"> 
												GST <span v-if="taxRequired=='yes'">({{ taxper }}%) </span>
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="text-right col-md-3" v-if="taxRequired=='yes'"> 
												{{ Indian_numberWithCommas(Math.round(afterTax)) }}
											</div>
										</div>	
									</li>
									<hr class="custom-hr"  >
									
									<li>
										<div class="row">
											<div class="col-md-8"> 
												<b>Total</b> 
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 fw-bold fs-4  text-right"> 
												<b>{{ Indian_numberWithCommas(Math.round(total_amount))}}</b>
											</div>
										</div>	
									</li>
									<li class="mt-4" >
										<div class="row bg-custom-gray">
											<div class="col-md-8"> 
												Security Deposit  
												<div v-if="json_convert(childvenue.settings.all_settings).allow_security==true">
													<i class="fs-11 text-danger" 
												v-if="json_convert(childvenue.settings.all_settings).security_refund ? true:false">
												(Refundable Deposit)</i>
											</div>
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 fw-bold fs-4 text-right" v-if="json_convert(childvenue.settings.all_settings).allow_security==true"> 
												{{Indian_numberWithCommas(json_convert(childvenue.settings.all_settings).security_deposit || 0)}}
											</div>
											<div class="col-md-3 fw-bold fs-4 text-right" v-else> 
												0
											</div>
										</div>	
									</li>
									<hr class="custom-hr"  >
									<li class="mt-2">
										<div class="row">
											<div class="col-md-8"> 
												<b>Booking Total </b>
												
											</div>
											<div class="col-md-1 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
											</div>
											<div class="col-md-3 fw-bold fs-4 text-right"> 
												<b>{{ Indian_numberWithCommas(Math.round(full_total_amount))}}</b>
											</div>
										</div>	
									</li>
								</ul>
								
								
							
								
							</b-card>
							<b-card class="mt-2">
								<b-form-checkbox id="proceed_with_quotation"
								v-model="form.proceed_with_quotation"
								value="yes"
								unchecked-value="no">Quotation</b-form-checkbox>
							</b-card>
							<span v-if="full_total_amount< form.paid_amount" class="text-danger">Amount error</span>
							<div class="text-center mt-2">
								&nbsp;
								<b-button v-if="permission_access1.vb_create == 1" size="sm" variant="primary" class="m-1" @click="bookingSaveDraft">Save Draft</b-button>
								<b-button v-if="permission_access.vb_create == 1"
								type="submit"
								variant="success" size="sm" 
								:disabled=" mobile_message!='' || errormessage_amount!='' || full_total_amount< form.paid_amount || form.applied_discount>this.childvenue.shift_price">
									
									Proceed
								</b-button>
								<router-link to="/my-booking"><b-button variant="danger" size="sm"  class="m-1">Cancel</b-button></router-link>
							</div>
							<span class="text-danger" v-if="permission_access.vb_create == 0"> Access Denied</span>
						</div>
					</div>
					
				</b-col>
				
			</b-row>
		</b-form>
		<br />
		<div v-if="this.show_booking_summary == true" id="PtopDiv">
			
			<!--  New Summary -->
			<b-card class="mb-4 w-100">
				<b-row class="mt-2">
					<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
						<span v-if="form.proceed_with_quotation=='no'">
						
						</span>
						<span v-if="form.proceed_with_quotation=='yes'">
							<h3 class="text-center">  Quotation Summary </h3>
						</span>
						<span v-else>
							<h3 class="text-center">  {{ form.payment_master_type=="booked" ?"Booking" : "Blocking" }}  Summary </h3>
						</span>
						<div class="d-flex justify-content-between">
							
							<div class="mt-2"> 
								Order date : <span class="text-danger fs-4"> {{date_format(this.today_date)}}</span>
							</div>
							<div class="mt-2"> 
								
								Form ID. <span class="text-danger fs-4 ">{{ Book_id }}</span>
							</div>
						</div>
						<hr>
						
						<b-row>
							<div class="col-lg-12">
								<table class="w-100">
									<tbody>
										<tr>
											<td><b>Venue Name</b></td>
											<td class="px-3">:</td>
											<td>{{ currentVenueName().text }}</td>
											
											<td class="text-left"><b>Event Date</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ date_format(this.form.start_booking_date) }}</td>
										</tr>
										<tr>
											<td><b>Shift</b></td>
											<td class="px-3">:</td>
											<td>{{ this.childvenue.shift_type }} Shift</td>
											<td><b>Timings</b></td>
											<td class="px-3">:</td>
											<td>{{ convert_24_12hour(this.form.stime) }} to {{ convert_24_12hour(this.form.etime) }}</td>
											
										</tr>
										<tr>
											<td><b>Event type</b></td>
											<td class="px-3">:</td>
											<td><span v-if="this.form.event != ''">{{ currentEventName().text }}</span></td>
											
											<td><b>Guest capacity </b></td>
											<td class="px-3">:</td>
											<td>{{ this.form.gustcapa }}</td>
											
											
										</tr>
										
									</tbody>
								</table>
							</div>
							
						</b-row>	
						<hr>
						
						<b-row>	
							<div class="col-lg-8">
								<h4> Customer details</h4>
								<table class="w-100">
									<tbody>
										<tr v-if="this.form.booking_for_self == 'no'" style="background-color:#F5F5F5;">
											<td style="width: 180px;"><b> Respresntative name</b></td>
											<td class="px-3">:</td>
											<td>{{ this.form.booking_for_name }}</td>
											
											<td style="width: 180px;" class="text-left"><b>Booking for relationship</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.form.booking_for_relationship }}</td>
											
											
										</tr>
										<tr  v-if="this.form.booking_for_self == 'no'" style="background-color:#F5F5F5;">
											<td style="width: 180px;" class="text-left"><b>Booking for mobile no</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.form.booking_for_mobile_no }}</td>
										</tr>
										<tr>
											<td style="width: 180px;"><b> Customer name</b></td>
											<td class="px-3">:</td>
											<td>{{ this.form.name }}</td>
											
											<td style="width: 180px;" class="text-left"><b>Email</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.form.email }}</td>
										</tr>
										<tr>
											<td style="width: 180px;" class="text-left"><b>Phone</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.form.contact }}</td>

											<td style="width: 180px;" class="text-left" v-if="this.form.Acontact!=''"><b>Alternate Phone No.</b></td>
											<td class="px-3" v-if="this.form.Acontact!=''">:</td>
											<td class="text-left" v-if="this.form.Acontact!=''">{{ this.form.Acontact }}</td>
										</tr>
										<tr>
											<td style="width: 180px;" ><b>Address</b></td>
											<td class="px-3">:</td>
											<td>{{ this.form.address }} </td>
										</tr>
										
										
									</tbody>
								</table>
							
						<hr v-if="this.form.bride_mob!='' || this.form.groom_mob!='' || this.form.sound!='' || this.form.music!='' || this.form.bride!='' || this.form.bridegroom!='' || this.form.bridelocation!='' || this.form.bridegroomlocation!='' || this.form.caterer!='' || this.form.decorator!='' || this.form.music!=''">
						<h4 v-if="this.form.bride_mob!='' || this.form.groom_mob!='' || this.form.sound!='' || this.form.music!='' || this.form.bride!='' || this.form.bridegroom!='' || this.form.bridelocation!='' || this.form.bridegroomlocation!='' || this.form.caterer!='' || this.form.decorator!='' || this.form.music!=''"  > Information</h4>
						
						
								<table class="w-100" >
									<tbody>
										<tr>
											<td style="width: 180px;" v-if="this.form.bride!=''"><b> Bride Name</b></td>
											<td v-if="this.form.bride!=''" class="px-3">:</td>
											<td v-if="this.form.bride!=''">{{ this.form.bride }}</td>
											
											<td style="width: 180px;" v-if="this.form.bride_mob!=''"><b> Bride Mobile No.</b></td>
											<td v-if="this.form.bride_mob!=''" class="px-3">:</td>
											<td v-if="this.form.bride_mob!=''">{{ this.form.bride_mob }}</td>
											
											
										</tr>
										<tr>
											<td style="width: 180px;" v-if="this.form.bridelocation!=''" class="text-left"><b> Bride City </b></td>
											<td v-if="this.form.bridelocation!=''" class="px-3">:</td>
											<td v-if="this.form.bridelocation!=''" class="text-left">{{ this.form.bridelocation }}</td>

											<td  style="width: 180px;" v-if="this.form.bridegroom!=''" class="text-left"><b> Groom Name</b></td>
											<td v-if="this.form.bridegroom!=''" class="px-3">:</td>
											<td v-if="this.form.bridegroom!=''" class="text-left">{{ this.form.bridegroom }}</td>
										</tr>	
										<tr>
											<td style="width: 180px;" v-if="this.form.groom_mob!=''" class="text-left"><b> Groom Mobile No</b></td>
											<td v-if="this.form.groom_mob!=''" class="px-3">:</td>
											<td v-if="this.form.groom_mob!=''" class="text-left">{{ this.form.groom_mob }}</td>
											
											<td  style="width: 180px;" v-if="this.form.bridegroomlocation!=''"><b>Groom City</b></td>
											<td v-if="this.form.bridegroomlocation!=''" class="px-3">:</td>
											<td v-if="this.form.bridegroomlocation!=''">{{ this.form.bridegroomlocation }} </td>
										</tr>
										<tr>
											<td  style="width: 180px;" v-if="this.form.caterer!=''" class="text-left"><b> Caterer Name </b></td>
											<td v-if="this.form.caterer!=''" class="px-3">:</td>
											<td v-if="this.form.caterer!=''" class="text-left">{{ this.form.caterer }}</td>
											
											<td  style="width: 180px;" v-if="this.form.decorator!=''" class="text-left"><b> Decorator Name </b></td>
											<td v-if="this.form.decorator!=''" class="px-3">:</td>
											<td v-if="this.form.decorator!=''" class="text-left">{{ this.form.decorator }}</td>
											
											
										</tr>
										<tr>
											<td  style="width: 180px;" v-if="this.form.music!=''"><b>Music person Name</b></td>
											<td v-if="this.form.music!=''" class="px-3">:</td>
											<td v-if="this.form.music!=''">{{ this.form.music }} </td>

											<td  style="width: 180px;" v-if="this.form.sound!=''"><b>Sound person Name</b></td>
											<td v-if="this.form.sound!=''" class="px-3">:</td>
											<td v-if="this.form.sound!=''">{{ this.form.sound }} </td>
										</tr>
										
									</tbody>
								</table>
							
						<hr>
						
								<h4> Add-Ons</h4>
								<div class="row">
									<span class="d-none">{{ amt=0 }}</span>
									
								
									<div class="col-md-3 col-xl-3 mb-4" v-for="(addon, index) in activeItem" :key="index">
										
										<div class="extradesign">
											<div class="card h-100">
												<div class="bg-label-primary rounded-3 text-center mb-0 pt-0">
													<img class="img-fluid fix-set" :src='addon[2]' alt="Card girl image" width="500">
												</div>
												<div class="mb-0 text-center">
													<span  v-if="addon[0].length<15" class="text-center">{{ addon[0] }}</span>
													<span  class="text-center" v-else>{{ addon[0].substring(0,15)+".." }}</span> <br>
													<p>  <i :class="{'mdi':true,[curncy]:true}"></i>{{Indian_numberWithCommas(addon[1])}} * {{ addon[3] }} <i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon[4]) }} </p>
													
												</div>
												
											</div>
										</div>
									</div>
								</div>

								</div>
								<div class="col-md-4" style="border-left:1px solid mediumorchid">
									<h4 class="text-left p-3" style="border-bottom:5px solid mediumorchid"> Pricing Summary</h4>
										<ul class="custom-amount-summary_1">
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left "> 
														Base Price
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold text-right" > 
														{{  Indian_numberWithCommas(this.childvenue.shift_price) }}
													</div>
												</div>	
											</li>
											
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														<b>Add-on :</b> 
													</div>
													<div class="col-md-1 fw-bold text-right" v-if="activeItem.length>0"> 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 text-right"  v-if="activeItem.length>0"> 
														{{  Indian_numberWithCommas(add_amount)}}
													</div>
												</div>	
											</li>
											<hr class="summary-hr" >
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														<b>Total Base Price</b>
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 text-right"> 
														<span class="">
															{{ Indian_numberWithCommas(addontotal) }}
														</span>
													</div>
												</div>	
											</li>
											
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														Discount({{ form.applied_discount_percentage }}%) 
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4  text-right" v-if="this.form.applied_discount != '' && this.form.applied_discount > 0"> 
														{{ Indian_numberWithCommas(this.form.applied_discount) }}
													</div>
												</div>	
											</li>
											<hr class="summary-hr">
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														<b>Sub total</b>
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div  class="col-md-4 text-right" > 
														<span class="">
															<b>{{Indian_numberWithCommas(Math.round(after_discount))}}</b>
														</span>
													</div>
												</div>	
											</li>
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														GST <span v-if="taxRequired=='yes'">({{ taxper }}%) </span>
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="text-right col-md-4" v-if="taxRequired=='yes'"> 
														{{ Indian_numberWithCommas(Math.round(afterTax)) }}
													</div>
												</div>	
											</li>
											<hr class="summary-hr" >
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														<b>Total</b>
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold fs-4  text-right" > 
														<span class="">
															{{ Indian_numberWithCommas(Math.round(total_amount))}}
														</span>
													</div>
												</div>	
											</li>
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1 bg-custom-gray">
													<div class="col-md-7 text-left"> 
														Security Deposit  
														<div v-if="json_convert(childvenue.settings.all_settings).allow_security==true" >
															<i class="fs-11 text-danger" v-if="json_convert(childvenue.settings.all_settings).security_refund ? true:false">(Refundable Deposit)</i>
														</div>
													</div>
													<div class="col-md-1 fw-bold text-right"  > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold fs-4  text-right" v-if="json_convert(childvenue.settings.all_settings).allow_security==true"> 
														<span class="">
															{{Indian_numberWithCommas(json_convert(childvenue.settings.all_settings).security_deposit || 0)}}
														</span>
													</div>
													<div class="col-md-4 fw-bold fs-4  text-right" v-else> 
														<span class="">
															0
														</span>
													</div>
												</div>	
											</li>
											<hr class="summary-hr" >
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														Booking Total
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold fs-4 text-success text-right" > 
														<span class="">
															<b>{{ Indian_numberWithCommas(Math.round(full_total_amount))}}</b>
														</span>
													</div>
												</div>	
											</li>
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														Paid Amount (Advance)
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold fs-4 text-success text-right" > 
														<span class="">
															<b>{{ Indian_numberWithCommas(Math.round(this.form.paid_amount))}}</b>
														</span>
													</div>
												</div>	
											</li>
											<hr class="summary-hr" >
											<li  class="mt-2 mb-2">
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left"> 
														Payable  
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold fs-4 text-success text-right" > 
														<span class="">
															<b>{{ Indian_numberWithCommas(Math.round(full_total_amount-this.form.paid_amount))}}</b>
														</span>
													</div>
												</div>	
											</li>
										</ul>
										Payment mode  {{this.form.payment_mode}}
<br>
<br>
<br>
										<vueSignature
							ref="signature"
							:sigOption="option"
							:w="'500px'"
							:h="'100px'"
							:disabled="disabled"
							:defaultUrl="dataUrl"
							style="border :1px solid black"
							></vueSignature>
							
								</div>
								</b-row>
							
						<div v-if="form.payment_type == 'block' || form.payment_type == 'advance'">
							<h5>Payment Confirmation</h5>
							<p v-if="form.payment_type == 'block'">Property Blocked for {{ this.form.blocked_days }} days with Rs. {{ this.form.paid_amount }}</p>
							<p v-if="form.payment_type == 'advance'">Advance Amount: Rs. {{ this.form.paid_amount }}</p>
							<hr>
						</div>
						
						
						<hr>
						<div>
							<h5>Special Requests</h5>
							<p v-html="this.form.special_request"></p>
						</div>
						<div>
							<b-form-checkbox size="sm" v-model="agree_terms_condition"> I have read and I agree with the <a href="https://getbootstrap.com/docs/5.0/utilities/flex/" target="blank">terms and conditions</a>. </b-form-checkbox>
						</div>
						<div class="text-center">
							<b-button variant="primary" class="m-2" @click="backToBooking">Back</b-button>
							<!-- <b-button variant="secondary" class="m-2">Quotation</b-button> -->
							<b-button variant="success" class="m-2" @click="bookOfflineVenue()" :disabled="agree_terms_condition==false || doubleEntry==true"> <b-spinner small label="Small Spinner" v-if="doubleEntry"></b-spinner> Submit</b-button>
							<b-button variant="danger" class="m-2" @click="$bvModal.show('modal-booking-cancel')">Cancel</b-button>
						</div>
					</b-col>
				</b-row>
			</b-card>	
			
			
		</div>
		<b-modal id="modal-booking-cancel" hide-footer>
			<div class="text-center">
				<b-button variant="primary" class="mr-2">Save Draft</b-button>
				<router-link to="/my-booking"><b-button variant="danger">Close</b-button></router-link>
			</div>
		</b-modal>
		<b-modal id="modal-booking" hide-footer>
			<div class="text-center">
				<h4>Your Booking is Confirmed!</h4>
				<router-link :to="'/my-booking/invoice/' + booking_id"><b-button variant="primary" class="mr-2">Raise Invoice</b-button></router-link>
				<router-link to="/my-booking"><b-button variant="primary">Close</b-button></router-link>
			</div>
		</b-modal>
		
	</div>
</template>
<script>
	import axios from "axios";
	import { mapState } from "vuex";
	import shift_timing from '../../mixins/shift_timing'; 
	
	import vueSignature from "vue-signature";
	
	import { validationMixin } from "vuelidate";
	import moment from "moment";
	import {
		required,
		email,
		minLength,
		maxLength,
		numeric,
	} from "vuelidate/lib/validators";
	import { VueEditor } from "vue2-editor";

	

	export default {
		name: "parent_venue_add",
		mixins: [validationMixin,shift_timing],
		data: () => ({
			title: "parent_venue_add",
			today_date: new Date().toISOString().substr(0, 10),
			show_add_booking: false,
			agree_terms_condition: false,
			show_booking_summary: false,
			booking_id:'',
			taxper :0,
			afterTax:0,
			total_amount:0,
			stock_count:0,
			add_amount:0,
			Book_id:'',
			activeItem:[],
			ava_meaasge:'',
			ava_color:'',
			OnlyWedding:false,
			parent_venue_id:'',
			baseUrl:'',
			permission_access:'',
			permission_access1:'',
			suggestions:'',
			countInput:[],
			countInputAll:[],
			add_ons_list:[],
			form: {
				name: "",
				email: "",
				contact: "",
				Acontact: "",
				address: "",
				event: "",
				start_booking_date: "",
				end_booking_date:"",
				child_selected: "",
				shift: "",
				shift_type:"",
				stime: "",
				etime: "",
				org_stime: "", 
				org_etime: "",
				price: "",
				add_on_select: "",
				question: "",
				payment_type: "full",
				paid_amount: 0,
				booking_for_self: "",
				booking_for_name:'',
				booking_for_relationship:'',
				booking_for_mobile_no:'',
				booking_for_mobile_no_:'',
				block_date:'',
				block_time_diff_display:'',
				selected_add_ons:[],
				applied_discount:0,
				booking_grand_total:0,
				booking_addons_total:0,
				blocked_days:'',
				payment_mode:'cash',
				special_request:'',
				proceed_with_quotation:'no',
				payment_master_type:'',
				payment_booked_type:'full',
				cheque_no:'',
				bank_name:'',
				bride:'',
				bridegroom:'',
				bridelocation:'',
				caterer:'',
				bridegroomlocation:'',
				sound:'',
				music:'',
				dancer:'',
				decorator:'',
				gustcapa:'',
				guest_rooms:'',
				CustomguestCapacity:'',
				ostime:'',
				oetime:'',
				bride_mob:'',
				groom_mob:''
			},
			shift_wise_new:{
				shifts_interval:30, //minutes interval
				morning_start_time: null,
				morning_end_time:null,
				afternoon_start_time: null,
				afternoon_end_time:null,
				evening_start_time:null,
				evening_end_time:null,
			},
			
			addon: [{ value: "", text: "Select Add on" }],
			timelist_from: [{ value: null, text: "Select time" }],
			timelist_end: [{ value: null, text: "Select time" }],
			child_option: [{ value: "", text: "Select Venue" }],
			payment_mode_option: [
			{ value: "cash", text: "Cash" },
			{ value: "online", text: "Online" },
			{ value: "cheque", text: "Cheque" }
			],
			shift_options: [{ value: "", text: "Select Shift" }],
			payment_type: [
			{ value: 'full', text: "Full" },
			{ value: 'advance' , text: "Advance" },
			],
			event_types: [{ value: "", text: "Select Event" }],
			childvenue:{
				addons:[],
				settings:[],
				attachment_path:'',
				shift_price:0,
				block_percentage:'',
				original_from_time:'',
				original_to_time:'',
				shift_type:'',
			},
			newChild_shift_time:[],
			checking_avaliblity:false,
			doubleEntry:false,
			fromchecking:"",
			applied_discount_percentage:"",
			addontotal:0,
			ad_addontotal:0,
			after_discount:0,
			full_total_amount:0,
			booked_type:'full',
			booked_val:'',
			taxRequired:'',
			curncy:'',
			mobile_message:'',
			errormessage_amount:'',
			path:'',
			/* Sticky */
			scrollY: null,
			headerTop: 0,
			isHeaderSticky: false,
			gst_perentage: 0,
			autoData: 0,
			
		}),
		validations: {
			form: {
				name: {
					required,
				},
				gustcapa: {
					required,
				},
				email: { required, email },
				contact: {
					required,
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
				},
				Acontact: {
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
				},
				address: {
					required,
				},
				event: {
					required,
				},
				start_booking_date: {
					required,
				},
				// end_booking_date: {
				//   required,
				// },
				child_selected: {
					required,
				},
				shift: {
					required,
				},
				stime: {
					required,
				},
				etime: {
					required,
				},
				// booked: {
				//   required,
				// },
				booking_for_mobile_no:{ numeric,
					minLength: minLength(10),
				maxLength: maxLength(10), },
				booking_for_mobile_no_:{ numeric,
					minLength: minLength(10),
				maxLength: maxLength(10), },
			},
		},
		components: { VueEditor,vueSignature },
		computed: {
			...mapState(["offline_calendar_booking"]),
			
		},
		methods: {
			validateState(name) {
				const { $dirty, $error } = this.$v.form[name];
				return $dirty ? !$error : null;
			},
			callChildVenue() {
				
				axios.get("/admin/fetch_child_venue_listing").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.form.child_selected = "";
							for (var i = 0; i < resp.data.child_venue_details.length; i++) {
								this.child_option.push({
									value: resp.data.child_venue_details[i].child_venue_id,
									text: resp.data.child_venue_details[i].child_venue_name,
								});
							}
							this.prefillDefaultCalendarData();
							} else {
							this.child_option = [];
						}
					}
				});
			},
			callChildVenueprice() {
				if (
				this.form.child_selected === "" ||
				this.form.child_selected === null
				) {
					this.shift = "";
					} else {
					var child_id = this.form.child_selected;
					axios
					.post("/api/child_venue_price_listing", {
						child_id: child_id,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.form.shift = "";
								for (var i = 0; i < resp.data.add_on.length; i++) {
									this.addon.push({
										value: resp.data.add_on[i].add_on_id,
										text: resp.data.add_on[i].add_on_name,
										img: resp.data.add_on[i].attachment,
									});
								}
								var type = resp.data.shiftselect[0].shift_types_selected;
								if (type == "1") {
									this.shift_options = [
									{ value: "", text: "Select shift" },
									{ value: "4", text: "Hourly" },
									];
								}
								if (type == "2") {
									this.shift_options = [
									{ value: "", text: "Select shift" },
									{ value: "1", text: "Morning" },
									{ value: "2", text: "Afternoon" },
									{ value: "3", text: "Evening" },
									];
								}
							}
						}
					});
				}
			},
			get_event_types() {
				axios.post("/api/get_event_types").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for (var i = 0; i < resp.data.event_types.length; i++) {
								this.event_types.push({
									value: resp.data.event_types[i].event_id,
									text: resp.data.event_types[i].event_name,
								});
							}
						}
					}
				});
			},
			vendor_check()
			{
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							if(resp.data.users.gst_no==null)
							{
								this.taxRequired='no';
							}
							else
							{
								this.taxRequired='yes';
							}
						}
					}
				});
			},
			gettime() {
				if (
				this.form.shift === "" ||
				(this.form.shift === null && this.form.child_selected === "") ||
				this.form.child_selected === null
				) {
					this.shift = null;
					} else {
					var child_id = this.form.child_selected;
					var shift_type = this.form.shift;
					
					axios
					.post("/api/child_venue_price_listing", {
						shift_type: shift_type,
						child_id: child_id,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.show_times(this.form.shift);
								
								//this.form.paid_amount =resp.data.minimum_paid;
								if (resp.data.child_venue_details.length == 0) {
									this.form.price = this.form.stime = this.form.etime = "";
									} else {
									this.form.price = resp.data.child_venue_details[0].price;
									
									var s_time = moment(
									resp.data.child_venue_details[0].from_time,
									"hh A"
									).format("hh:mm A");
									var e_time = moment(
									resp.data.child_venue_details[0].to_time,
									"hh A"
									).format("hh:mm A");
									this.form.stime = s_time;
									this.form.etime = e_time;
									console.log(this.form.etime);
								}
							}
						}
					});
					this.checkShiftsAvailability();
				}
			},
			show_times_old(type) {
				if (type === "1") {
					this.timelist_from = this.timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "06:00 AM", text: "06:00 AM" },
					{ value: "07:00 AM", text: "07:00 AM" },
					{ value: "08:00 AM", text: "08:00 AM" },
					{ value: "09:00 AM", text: "09:00 AM" },
					{ value: "10:00 AM", text: "10:00 AM" },
					{ value: "11:00 AM", text: "11:00 AM" },
					{ value: "12:00 PM", text: "12:00 PM" },
					];
				}
				if (type === "2") {
					this.timelist_from = this.timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "12:00 PM", text: "12:00 PM" },
					{ value: "01:00 PM", text: "01:00 PM" },
					{ value: "02:00 PM", text: "02:00 PM" },
					{ value: "03:00 PM", text: "03:00 PM" },
					{ value: "04:00 PM", text: "04:00 PM" },
					{ value: "05:00 PM", text: "05:00 PM" },
					];
				}
				if (type === "3") {
					this.timelist_from = this.timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "06:00 PM", text: "06:00 PM" },
					{ value: "07:00 PM", text: "07:00 PM" },
					{ value: "08:00 PM", text: "08:00 PM" },
					{ value: "09:00 PM", text: "09:00 AM" },
					{ value: "10:00 PM", text: "10:00 PM" },
					{ value: "11:00 PM", text: "11:00 PM" },
					{ value: "12:00 AM", text: "12:00 AM" },
					];
				}
				if (type === "4") {
					this.timelist_from = this.timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "06:00 AM", text: "06:00 AM" },
					{ value: "07:00 AM", text: "07:00 AM" },
					{ value: "08:00 AM", text: "08:00 AM" },
					{ value: "09:00 AM", text: "09:00 AM" },
					{ value: "10:00 AM", text: "10:00 AM" },
					{ value: "11:00 AM", text: "11:00 AM" },
					{ value: "12:00 PM", text: "12:00 PM" },
					{ value: "01:00 PM", text: "01:00 PM" },
					{ value: "02:00 PM", text: "02:00 PM" },
					{ value: "03:00 PM", text: "03:00 PM" },
					{ value: "04:00 PM", text: "04:00 AM" },
					{ value: "05:00 PM", text: "05:00 PM" },
					{ value: "06:00 PM", text: "06:00 PM" },
					{ value: "07:00 PM", text: "07:00 PM" },
					{ value: "08:00 PM", text: "08:00 PM" },
					{ value: "09:00 PM", text: "09:00 AM" },
					{ value: "10:00 PM", text: "10:00 PM" },
					{ value: "11:00 PM", text: "11:00 PM" },
					{ value: "12:00 AM", text: "12:00 AM" },
					];
				}
			},
			generateHoursInterval(startHourInMinute,endHourInMinute){
				var times = [], hh, mm, curr_time, fomated_time;
				times.push({
					value: null,
					text: "Select time",
				});
				
				for (let i = 0; startHourInMinute <= 24 * 60; i++) {
					if (startHourInMinute > endHourInMinute) break;
					hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format
					mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format
					curr_time = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);
					fomated_time = moment(curr_time, "hh:mm").format(
					"hh:mm A"
					);
					times.push({
						value: fomated_time,
						text: fomated_time,
					});
					startHourInMinute = startHourInMinute + this.shift_wise_new.shifts_interval;
				}
				return times;
			},
			calculateMinuteTime(selected_time){
				// var startHourInMinute = 60 * startTime; // start time in minutes
				var timeInMinutes;
				selected_time = moment(selected_time, "hh:mm A").format("HH:mm");
				timeInMinutes = moment.duration(selected_time).asMinutes();
				if(timeInMinutes == 0){
					timeInMinutes = 1440;
				}
				return timeInMinutes;
			},
			//bellow function will add 30 minute to time ex. if 6:00 AM then return 6:30 AM's minutes
			addIntervalMinutes(selected_time)
			{
				var timeInMinutes = this.calculateMinuteTime(selected_time) + this.shift_wise_new.shifts_interval;
				return timeInMinutes;
			},
			//bellow function will remove 30 minute from time ex. if 11:30 PM then return 11:00 PM's minutes
			removeIntervalMinutes(selected_time)
			{
				var timeInMinutes = this.calculateMinuteTime(selected_time) - this.shift_wise_new.shifts_interval;
				return timeInMinutes;
			},
			show_times(type) {
				this.timelist_from = [{ value: null, text: "select time" }];
				this.timelist_end = [{ value: null, text: "select time" }];
				if (type === "1") {
					this.timelist_from = this.timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.morning_start_time), this.calculateMinuteTime(this.shift_wise_new.morning_end_time));
				}
				if (type === "2") {
					this.timelist_from = this.timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.afternoon_start_time), this.calculateMinuteTime(this.shift_wise_new.afternoon_end_time));
				}
				if (type === "3") {
					this.timelist_from = this.timelist_end = this.generateHoursInterval(this.calculateMinuteTime(this.shift_wise_new.evening_start_time), this.calculateMinuteTime(this.shift_wise_new.evening_end_time));
				}
				if (type === "4") {
					this.timelist_from = this.timelist_end = [
					{ value: null, text: "Select time" },
					{ value: "06:00 AM", text: "06:00 AM" },
					{ value: "07:00 AM", text: "07:00 AM" },
					{ value: "08:00 AM", text: "08:00 AM" },
					{ value: "09:00 AM", text: "09:00 AM" },
					{ value: "10:00 AM", text: "10:00 AM" },
					{ value: "11:00 AM", text: "11:00 AM" },
					{ value: "12:00 PM", text: "12:00 PM" },
					{ value: "01:00 PM", text: "01:00 PM" },
					{ value: "02:00 PM", text: "02:00 PM" },
					{ value: "03:00 PM", text: "03:00 PM" },
					{ value: "04:00 PM", text: "04:00 AM" },
					{ value: "05:00 PM", text: "05:00 PM" },
					{ value: "06:00 PM", text: "06:00 PM" },
					{ value: "07:00 PM", text: "07:00 PM" },
					{ value: "08:00 PM", text: "08:00 PM" },
					{ value: "09:00 PM", text: "09:00 AM" },
					{ value: "10:00 PM", text: "10:00 PM" },
					{ value: "11:00 PM", text: "11:00 PM" },
					{ value: "12:00 AM", text: "12:00 AM" },
					];
				}
			},
			updateShiftsTimingShiftWise(start_time, end_time) {
				if (this.form.stime != null) {
					this.timelist_end = this.generateHoursInterval(this.addIntervalMinutes(this.form.stime), this.calculateMinuteTime(end_time));
				}
				if (this.form.etime != null) {
					this.timelist_from = this.generateHoursInterval(this.calculateMinuteTime(start_time), this.removeIntervalMinutes(this.form.etime));
				}
			},
			
			convertTime12to24(time12h) {
				const [time, modifier] = time12h.split(" ");
				let [hours] = time.split(":");
				if (modifier === "PM" && hours < 12) {
					hours = parseInt(hours, 10) + 12;
				}
				if (modifier === "AM" && hours == 12) {
					hours = 24;
				}
				return `${hours}`;
			},
			convertTime24to12(hours) {
				if (hours == 24) {
					hours = 0;
				}
				let minutes = 0;
				const ampm = hours >= 12 ? "PM" : "AM";
				hours %= 12;
				hours = hours || 12;
				hours = hours < 10 ? `0${hours}` : hours;
				minutes = minutes < 10 ? `0${minutes}` : minutes;
				const strTime = `${hours}:${minutes} ${ampm}`;
				return strTime;
			},
			updateShiftsStartTime(start_time) {
				var convertedEndTime = parseInt(this.convertTime12to24(this.form.etime));
				var curr_time, time24;
				this.timelist_from = [];
				this.timelist_from.push({
					value: null,
					text: "Select time",
				});
				
				//update start time to selcted Endtime
				curr_time = start_time;
				while (curr_time <= convertedEndTime) {
					time24 = this.convertTime24to12(curr_time);
					this.timelist_from.push({
						value: time24,
						text: time24,
					});
					curr_time = parseInt(curr_time) + 1;
				}
			},
			suggest_getChildVenueDetails(child_id)
			{
				this.form.child_selected = child_id;
				setTimeout(this.getChildVenueDetails, 100);
			},
			getChildVenueDetails_OnLoad(){
				this.add_ons_list  =[];
				this.add_on_details =[];
				axios
				.post("/api/VenueDetails", {
					child_venue_id: this.form.child_selected,
				})
				.then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						//Saleem Added new 
						this.Book_id = resp.data.Book_id;
						this.path = resp.data.path;
						this.newChild_shift_time = resp.data.child_new_venue_details;
						this.childvenue.add_on_categories = resp.data.child_venue_details[0].add_on_categories;
						this.childvenue.addOns_cat = resp.data.child_venue_details[0].addOns_cat;
						this.childvenue.addons = resp.data.child_venue_details[0].add_ons;
						this.childvenue.settings = resp.data.child_venue_details[0];
						this.form.guest_rooms = resp.data.child_venue_details[0].guest_rooms;
						this.form.gustcapa = resp.data.child_venue_details[0].guest_rooms;
						this.parent_venue_id = resp.data.parentVenue.parent_venue_id;//new
						this.gst_perentage = resp.data.users_tax.gst_perentage;//new
						setTimeout(this.checkGuestCapcity(), 100);
						for(var i = 0; i< this.childvenue.addons.length;i++)
						{
							this.countInput[this.childvenue.addons[i].auto_increment] =1;
						}
					
					}
				});
			},
			getChildVenueDetails(){
				this.add_amount=0;
				axios
				.post("/api/VenueDetails", {
					child_venue_id: this.form.child_selected,
				})
				.then((resp) => {
					this.activeItem=[];
					this.suggestions=[];
					this.form.selected_add_ons=[];
					this.add_ons_list  =[];
					this.add_on_details =[];
					if (resp.data.status_code == 200 && resp.data.status) {
						this.addontotal=0;
						this.Book_id = resp.data.Book_id;
						this.path = resp.data.path;
						this.newChild_shift_time = resp.data.child_new_venue_details;
						this.childvenue.add_on_categories = resp.data.child_venue_details[0].add_on_categories;
						this.childvenue.addOns_cat = resp.data.child_venue_details[0].addOns_cat;
						this.childvenue.addons = resp.data.child_venue_details[0].add_ons;
						this.childvenue.settings = resp.data.child_venue_details[0];
						this.form.guest_rooms = resp.data.child_venue_details[0].guest_rooms;
						this.form.gustcapa = resp.data.child_venue_details[0].guest_rooms;
						this.parent_venue_id = resp.data.parentVenue.parent_venue_id;//new
						this.gst_perentage = resp.data.users_tax.gst_perentage;//new
						this.gettimenew();
						
						for(var i = 0; i< this.childvenue.addons.length;i++)
						{
							this.countInput[this.childvenue.addons[i].auto_increment] =0;
						}
						
						
						
				
					}
				});
			},
			updateShiftsEndTime(end_time) {
				var convertedStartTime = parseInt(
				this.convertTime12to24(this.form.stime)
				);
				var curr_time, time24;
				this.timelist_end = [];
				this.timelist_end.push({
					value: null,
					text: "Select time",
				});
				
				//update end time to selcted starttime
				curr_time = convertedStartTime;
				while (curr_time <= end_time) {
					time24 = this.convertTime24to12(curr_time);
					this.timelist_end.push({
						value: time24,
						text: time24,
					});
					curr_time = parseInt(curr_time) + 1;
				}
			},
			
			updateShiftsTime() {
				//passed 24 hours formate time
				if (this.form.shift === "1") {
					this.updateShiftsTimingShiftWise(this.shift_wise_new.morning_start_time, this.shift_wise_new.morning_end_time);
					} else if (this.form.shift === "2") {
					this.updateShiftsTimingShiftWise(this.shift_wise_new.afternoon_start_time, this.shift_wise_new.afternoon_end_time);
					} else if (this.form.shift === "3") {
					this.updateShiftsTimingShiftWise(this.shift_wise_new.evening_start_time, this.shift_wise_new.evening_end_time);
					} else {
					this.updateShiftsTimingShiftWise(6, 24);
				}
				this.checkShiftsAvailability();
			},
			handleProceedBooking(){
				
				if(this.form.booking_for_self==""  )
				{
					this.$root.$refs.app.showToast("danger", 'Please Check booking for yourself ');
					return true;
				}
				else if(this.form.payment_master_type=="")
				{
					if(this.form.proceed_with_quotation=='yes')
					{
						
						this.form.paid_amount =0 ;
						//this.form.applied_discount = 0;
						this.show_booking_summary = true;
					}
					else
					{
						this.$root.$refs.app.showToast("danger", 'Please Check Payment ');
						return true;
					}
					
				}
				else if(this.payment_mode=="")
				{
					this.$root.$refs.app.showToast("danger", 'Please Check Payment Mode ');
					return true;
				}
				
				this.$v.form.$touch();
				if (!this.$v.form.$anyError) {
					
					if(this.form.proceed_with_quotation=='yes')
					{
						this.form.paid_amount =0 ;
						//this.form.applied_discount = 0;
					}
					
					document.documentElement.scrollTop = 0;
					
					this.show_booking_summary = true;
					
					// if(this.form.proceed_with_quotation == 'yes'){
					//   this.fetchQuotation();
					// }else{
					//   this.show_booking_summary = true;
					// }
				}
			},
			backToBooking(){
				document.documentElement.scrollTop = 0;
				this.show_booking_summary = false;
			},
			
			bookOfflineVenue(){
				var flag=0;
				if(this.form.proceed_with_quotation == 'yes'){
					this.fetchQuotation();
					}else{
					var to_time = this.convertTime12to24(this.form.etime);
					if(to_time == 24){
						to_time = '23:59:59';
						}else{
						to_time = to_time + ':00:00';
					}
					
					if(this.form.payment_master_type == 'booked'){
						this.form.payment_type = this.form.payment_booked_type;
						}else{
						this.form.payment_type = 'block';
					}
					if(flag==0)
					{
						var _this = this;
						var png = _this.$refs.signature.save();
						
						this.doubleEntry=true;
						axios
						.post("/api/offline_book_venue", {
							child_venue_id: this.form.child_selected,
							from_date: this.form.start_booking_date,
							to_date: this.form.start_booking_date,
							booked_shift_type: this.form.shift,
							from_time: this.convertTime12to24(this.form.stime)+':00:00',
							to_time: to_time,
							
							billing_first_name: this.form.name,
							billing_email: this.form.email,
							billing_phone: this.form.contact,
							billing_alt_phone: this.form.Acontact,
							billing_address: this.form.address,
							booking_event_type_id: this.form.event,
							
							billing_bride_name: this.form.bride,
							billing_bridegroom: this.form.bridegroom,
							billing_bridelocation: this.form.bridelocation,
							billing_bridegroomlocation: this.form.bridegroomlocation,
							billing_caterer: this.form.caterer,
							booking_sound: this.form.sound,
							booking_music: this.form.music,
							booking_decorator: this.form.decorator,
							
							bride_mob: this.form.bride_mob,
							groom_mob: this.form.groom_mob,
							
							gustcapa: this.form.gustcapa,
							
							taxper: this.taxper,
							security_deposit: JSON.parse(this.childvenue.settings.all_settings).allow_security==true ? JSON.parse(this.childvenue.settings.all_settings).security_deposit ?JSON.parse(this.childvenue.settings.all_settings).security_deposit : 0   : 0,
							
							booking_for_self:this.form.booking_for_self,
							booking_for_name:this.form.booking_for_name,
							booking_for_relationship:this.form.booking_for_relationship,
							booking_for_mobile_no:this.form.booking_for_mobile_no,
							
							booking_type: this.form.payment_type,
							payment_mode: this.form.payment_mode,
							paid_price: this.form.paid_amount,
							applied_discount:this.form.applied_discount,
							block_date:this.form.block_date,
							total_booking_price:this.childvenue.shift_price,
							cheque_no:this.form.cheque_no,
							bank_name:this.form.bank_name,
							
							online_name:this.form.onlinename,
							online_bank_name:this.form.online_bank_name,
							
							booked_no_of_people:1, //pass default due to updates in the field,
							add_on_details: JSON.stringify(this.form.selected_add_ons),
							add_ons_list:  JSON.stringify(this.add_ons_list),
							original_from_time: this.childvenue.original_from_time,
							original_to_time: this.childvenue.original_to_time,
							
							special_request:this.form.special_request,
							payment_type:this.form.payment_booked_type,//form.payment_booked_type
							signature:png
						})
						.then((resp) => {
							if (resp.data.status) {
								this.doubleEntry=false;
								this.$root.$refs.app.showToast("success", resp.data.message);
								this.booking_id = resp.data.booking_id;
								if(this.form.payment_master_type == 'blocked'){
									//this.$router.push('/my-booking/blocked-booking/'+this.booking_id);
									this.$router.push('/my-booking/invoice/'+this.booking_id);
									}else{
									//this.$bvModal.show('modal-booking');
									this.$router.push('/my-booking/invoice/'+this.booking_id);
								}
								} else {
								this.$root.$refs.app.showToast("danger", resp.data.message);
							}
						});
						flag++;
					}
					
				}
			},
			bookingSaveDraft(){
				var to_time;
				if(this.form.etime == '' || this.form.etime == null){
					to_time = '';
					}else{
					to_time = this.convertTime12to24(this.form.etime);
					if(to_time == 24){
						to_time = '23:59:59';
						}else{
						to_time = to_time + ':00:00';
					}
				}
				
				var from_time;
				if(this.form.stime == '' || this.form.stime == null){
					from_time = '';
					}else{
					from_time = this.convertTime12to24(this.form.stime)+':00:00';
				}
				
				if(this.form.payment_master_type == 'booked'){
					this.form.payment_type = this.form.payment_booked_type;
					}else{
					this.form.payment_type = 'block';
				}
				
				axios
				.post("/api/offline_save_draft_venue", {
					type:'draft',
					child_venue_id: this.form.child_selected,
					from_date: this.form.start_booking_date,
					to_date: this.form.start_booking_date,
					booked_shift_type: this.form.shift,
					from_time: from_time,
					to_time: to_time,
					
					billing_first_name: this.form.name,
					billing_email: this.form.email,
					billing_phone: this.form.contact,
					billing_alt_phone: this.form.Acontact,
					billing_address: this.form.address,
					booking_event_type_id: this.form.event,
					
					booking_for_self:this.form.booking_for_self,
					booking_for_name:this.form.booking_for_name,
					booking_for_relationship:this.form.booking_for_relationship,
					booking_for_mobile_no:this.form.booking_for_mobile_no,
					
					billing_bride_name: this.form.bride,
					billing_bridegroom: this.form.bridegroom,
					billing_bridelocation: this.form.bridelocation,
					billing_bridegroomlocation: this.form.bridegroomlocation,
					billing_caterer: this.form.caterer,
					booking_sound: this.form.sound,
					booking_music: this.form.music,
					booking_decorator: this.form.decorator,
					
					bride_mob: this.form.bride_mob,
					groom_mob: this.form.groom_mob,
					
					gustcapa: this.form.gustcapa,
					
					booking_type: this.form.payment_type,
					payment_mode: this.form.payment_mode,
					paid_price: this.form.paid_amount,
					applied_discount:this.form.applied_discount,
					block_date:this.form.block_date,
					total_booking_price:this.childvenue.shift_price,
					cheque_no:this.form.cheque_no,
					bank_name:this.form.bank_name,
					
					taxper: this.taxper,
					//security_deposit: this.json_convert(this.childvenue.settings.all_settings).allow_security==true ?this.json_convert(this.childvenue.settings.all_settings).security_deposit : 0,
					security_deposit: JSON.parse(this.childvenue.settings.all_settings).allow_security==true ? JSON.parse(this.childvenue.settings.all_settings).security_deposit ? JSON.parse(this.childvenue.settings.all_settings).security_deposit : 0   : 0,
					
					booked_no_of_people:1, //pass default due to updates in the field,
					add_on_details: JSON.stringify(this.form.selected_add_ons),
					add_ons_list:  JSON.stringify(this.add_ons_list),
					original_from_time: this.childvenue.original_from_time,
					original_to_time: this.childvenue.original_to_time,
					
					special_request:this.form.special_request,
					payment_type:this.form.payment_booked_type,//form.payment_booked_type
				})
				.then((resp) => {
					if (resp.data.status) {
						this.$root.$refs.app.showToast("success", resp.data.message);
						this.$router.push("/drafts-booking");
						} else {
						this.$root.$refs.app.showToast("danger", resp.data.message);
					}
				});
			},
			handleSubmit() {
				this.$v.form.$touch();
				alert(3);
				if (!this.$v.form.$anyError) {
					// this.show_add_booking = true;
					this.show_booking_summary = true;
					alert(1);
					console.log("add on 2",this.form.selected_add_ons);
					
					}else{
					console.log("error : ",this.$v.form.$anyError);
					alert(2);
				}
			},
			submitBooking(value) {
				var shift_val;
				if (this.form.shift === "4") {
					shift_val = 1;
					} else {
					shift_val = 2;
				}
				if(this.form.booking_for_self=="" )
				{
					alert("Field required")
					return true;
				}
				axios
				.post("/api/offline_book_venue", {
					child_venue_id: this.form.child_selected,
					booking_event_type_id: this.form.event,
					from_date: this.form.start_booking_date,
					to_date: this.form.end_booking_date,
					from_time: this.form.stime,
					to_time: this.form.etime,
					booking_type: this.form.booked,
					total_booking_price: this.form.price,
					paid_price: value.price,
					add_on_details: JSON.stringify(this.form.add_on_select),
					add_ons_list: JSON.stringify(this.add_ons_list),
					
					gustcapa: this.form.gustcapa,
					
					billing_first_name: this.form.name,
					// billing_last_name: this.billing_details.last_name,
					billing_email: this.form.email,
					billing_phone: this.form.contact,
					billing_alt_phone: this.form.Acontact,
					billing_address: this.form.address,
				
					booked_shift_type: shift_val,
					original_from_time: value.from_time,
					original_to_time: value.to_time,
					question: this.form.question,
					payment_type:this.form.payment_booked_type,//form.payment_booked_type
				})
				.then((resp) => {
					this.show_add_booking = false;
					if (resp.data.status) {
						// alert("Booking Saved");
						this.$root.$refs.app.showToast("suceess", resp.data.message);
						this.$router.push("/my-booking");
						} else {
						// alert("Booking Failed");
						this.$root.$refs.app.showToast("danger", resp.data.message);
					}
				});
			},
			changePaymentType(){
				if(this.form.payment_type == 'block' && this.childvenue.shift_price != ''){
					//this.form.paid_amount = ((this.childvenue.block_percentage/ 100) * this.childvenue.shift_price);
					}else{
					//this.form.paid_amount = this.childvenue.shift_price;
				}
			},
			checkShiftsAvailability(){
				this.add_amount=0;
				setTimeout(this.checkShiftsAvailabilityFun, 100);
				
				//this.checkShiftsAvailabilityFun();
				
			},
			checkShiftsAvailabilityFun(){
			
				
				if((this.form.start_booking_date != null && this.form.start_booking_date && this.form.shift != null && this.form.shift && this.form.stime != null && this.form.stime && this.form.etime != null && this.form.etime)){
					this.checking_avaliblity=true;
					axios
					.post("/api/check_shift_availability", {
						child_venue_id: this.form.child_selected,
						selected_shift_type: this.form.shift,
						start_date:this.form.start_booking_date,
						end_date:this.form.start_booking_date,
						start_time:this.form.stime,
						end_time:this.form.etime,
						booked_no_of_people:1,
					})
					.then((resp) => {
						this.checking_avaliblity=false;
						this.fromchecking="";
						this.addontotal=0;
						if (resp.data.status) {
							
							//	this.$root.$refs.app.showToast("success", resp.data.message);
							this.ava_meaasge="Available";//resp.data.message;
							this.ava_color='text-success';
							this.childvenue.shift_price = resp.data.available_shifts.price;
							this.childvenue.original_from_time = resp.data.available_shifts.from_time;
							this.childvenue.original_to_time = resp.data.available_shifts.to_time;
							this.childvenue.shift_type = resp.data.available_shifts.shift_type;
							//org_stime org_etime
							this.form.org_stime = resp.data.available_shifts.shift_type;
							this.form.org_etime = resp.data.available_shifts.shift_type;
							} else {
							
							//	this.$root.$refs.app.showToast("danger", resp.data.message);
							this.ava_meaasge=resp.data.message;
							this.ava_color='text-danger';
							this.childvenue.shift_price = 0;
							this.form.start_booking_date = '';
							this.form.stime = '';
							this.form.etime = '';
							this.form.shift_type = '';
							this.form.start_booking_date = "";
							this.form.shift = "";
							this.timelist_from = this.timelist_end = [
							{ value: "", text: "Select time" },
							];
						}
						this.autocalculation(this.childvenue.shift_price);
						this.check_booking_payment();
						//this.form.paid_amount = this.childvenue.shift_price;
						
						this.calculateBookingGrandTotal();
					});
				}
			},
			currentVenueName() {
				return this.child_option.find(option => option.value === this.form.child_selected)
			},
			currentEventName() {
				return this.event_types.find(option => option.value === this.form.event)
			},
			updateBlockTime(){
				const dateB = moment(this.today_date);
				const dateC = moment(this.form.block_date);
				this.form.blocked_days = dateC.diff(dateB, 'days');
				this.form.block_time_diff_display = this.form.blocked_days + ' days until ' + moment(this.form.block_date).format('DD MMMM YYYY') + ' 11:59 PM Blocked.';
			},
			checkDiscount(type){
				if(type=="percent")
				{
					//var discheck= Math.round(this.addontotal*this.form.applied_discount_percentage/100);
					var discheck= Math.round(this.childvenue.shift_price*this.form.applied_discount_percentage/100);
					if(discheck>100)
					{
						this.form.applied_discount=discheck;
					}
					
					
				}
				else{
					discheck=(this.form.applied_discount/this.childvenue.shift_price)*100;
					//discheck=(this.form.applied_discount/this.addontotal)*100;
					if(discheck<this.childvenue.shift_price)
					{
						this.form.applied_discount_percentage=discheck.toFixed(2);
						//this.form.applied_discount=Math.round(discheck);
					}
					
				}
				
				this.autocalculation(this.addontotal);
				
				//	var perscnt=(this.form.applied_discount/this.addontotal*100);
				//this.form.applied_discount_percentage=perscnt+"%";
				
				
				
				this.calculateBookingGrandTotal();
				
				
			},
			
			
			checkGst(amt){
				return amt;
			},
			updateAddOn(){
				this.calculateBookingGrandTotal();
			},
			setupPaymentTypes(allow_to_block){
				this.payment_type = [];
				this.payment_type = [
				{ value: 'full', text: "Full" },
				{ value: 'advance' , text: "Advance" },
				];
				if(allow_to_block == 1){
					this.payment_type.push({ 
						value: 'block'  , text: "Block" 
					});
				}
			},
			calculateBookingGrandTotal(){
				
				this.form.booking_grand_total = 0;
				this.form.booking_addons_total = 0;
				if(this.childvenue.shift_price != '' && this.childvenue.shift_price > 0){
					this.form.booking_grand_total = parseInt(this.form.booking_grand_total) + parseInt(this.childvenue.shift_price);
				}
				if(this.form.selected_add_ons.length > 0){
					for(var i=0; i < this.childvenue.addons.length; i++){
						if(this.form.selected_add_ons.indexOf(this.childvenue.addons[i].add_on_id) !== -1){
							this.form.booking_addons_total = parseInt(this.form.booking_addons_total) + parseInt(this.childvenue.addons[i].price);
						}
					}
					this.form.booking_grand_total = parseInt(this.form.booking_grand_total) + parseInt(this.form.booking_addons_total); 
				}
				if(this.form.applied_discount != '' && this.form.applied_discount > 0 && this.form.booking_grand_total > this.form.applied_discount){
					this.form.booking_grand_total = parseInt(this.form.booking_grand_total) - parseInt(this.form.applied_discount);
				}
			},
			/* Calender get from here */
			prefillDefaultCalendarData(){
				
				console.log(this.offline_calendar_booking);
				if(this.offline_calendar_booking.length){
					this.form.child_selected = this.offline_calendar_booking[0].childvenue_id;
					
					this.getChildVenueDetails_OnLoad();
					//this.form.shift = this.offline_calendar_booking[0].time_shift_type.toString();
					this.form.shift = this.offline_calendar_booking[0].time_shift_type;
					this.form.stime = this.offline_calendar_booking[0].from_time;
					this.form.ostime = this.offline_calendar_booking[0].from_time;
					this.form.oetime = this.offline_calendar_booking[0].to_time;
					this.form.etime = this.offline_calendar_booking[0].to_time;
					this.fromchecking="calender";
					//alert(this.form.shift);
					this.gettimenew();
					//this.checkShiftsAvailability();
					this.form.start_booking_date = this.offline_calendar_booking[0].year + '-' + this.leadingZero(this.offline_calendar_booking[0].month) + '-' + this.leadingZero(this.offline_calendar_booking[0].shift_date);
					
					this.$store.commit("SET_OFFLINE_CHILDVENUE", []);
				}
			},
			fetchQuotation(){
				var to_time;
				if(this.form.etime == '' || this.form.etime == null){
					to_time = '';
					}else{
					to_time = this.convertTime12to24(this.form.etime);
					if(to_time == 24){
						to_time = '23:59:59';
						}else{
						to_time = to_time + ':00:00';
					}
				}
				
				var from_time;
				if(this.form.stime == '' || this.form.stime == null){
					from_time = '';
					}else{
					from_time = this.convertTime12to24(this.form.stime)+':00:00';
				}
				
				if(this.form.payment_master_type == 'booked'){
					this.form.payment_type = this.form.payment_booked_type;
					}else{
					this.form.payment_type = 'block';
				}
				
				axios
				.post("/api/offline_save_draft_venue", {
					type:'quotation',
					child_venue_id: this.form.child_selected,
					from_date: this.form.start_booking_date,
					to_date: this.form.start_booking_date,
					booked_shift_type: this.form.shift,
					from_time: from_time,
					to_time: to_time,
					
					billing_first_name: this.form.name,
					billing_email: this.form.email,
					billing_phone: this.form.contact,
					billing_alt_phone: this.form.Acontact,
					billing_address: this.form.address,
					booking_event_type_id: this.form.event,
					
					booking_for_self:this.form.booking_for_self,
					booking_for_name:this.form.booking_for_name,
					booking_for_relationship:this.form.booking_for_relationship,
					booking_for_mobile_no:this.form.booking_for_mobile_no,
					
					billing_bride_name: this.form.bride,
					billing_bridegroom: this.form.bridegroom,
					billing_bridelocation: this.form.bridelocation,
					billing_bridegroomlocation: this.form.bridegroomlocation,
					billing_caterer: this.form.caterer,
					booking_sound: this.form.sound,
					booking_music: this.form.music,
					booking_decorator: this.form.decorator,
					
					bride_mob: this.form.bride_mob,
					groom_mob: this.form.groom_mob,
					
					booking_type: this.form.payment_type,
					payment_mode: this.form.payment_mode,
					paid_price: this.form.paid_amount,
					applied_discount:this.form.applied_discount,
					block_date:this.form.block_date,
					total_booking_price:this.childvenue.shift_price,
					cheque_no:this.form.cheque_no,
					bank_name:this.form.bank_name,
					
					gustcapa: this.form.gustcapa,
					
					taxper: this.taxper,
					//	security_deposit: this.json_convert(this.childvenue.settings.all_settings).allow_security==true ?this.json_convert(this.childvenue.settings.all_settings).security_deposit : 0,
					security_deposit: JSON.parse(this.childvenue.settings.all_settings).allow_security==true ? JSON.parse(this.childvenue.settings.all_settings).security_deposit ?JSON.parse(this.childvenue.settings.all_settings).security_deposit : 0   : 0,
					
					booked_no_of_people:1, //pass default due to updates in the field,
					add_on_details: JSON.stringify(this.form.selected_add_ons),
					add_ons_list:  JSON.stringify(this.add_ons_list),
					original_from_time: this.childvenue.original_from_time,
					original_to_time: this.childvenue.original_to_time,
					
					special_request:this.form.special_request,
					payment_type:this.form.payment_booked_type,//form.payment_booked_type
				})
				.then((resp) => {
					if (resp.data.status) {
						this.$root.$refs.app.showToast("success", resp.data.message);
						this.$router.push("/my-booking/view_details/"+resp.data.booking_save_draft_id);
						//this.$router.push("/my-booking/quotation/"+resp.data.booking_save_draft_id);
						} else {
						this.$root.$refs.app.showToast("danger", resp.data.message);
					}
				});
			},
			fetchQuotation_old(){
				var discount;
				if(this.form.applied_discount == ''){
					discount = 0;
					}else{
					discount = this.form.applied_discount;
				}
				
				var offline_booking_quotation = [{
					venue_name:this.currentVenueName().text,
					booking_date:this.today_date,
					shift:this.form.shift_type,
					event_type:this.currentEventName().text,
					base_price:this.childvenue.shift_price,
					discount:discount,
					from_time:this.form.stime,
					to_time:this.form.etime,
					grand_total:this.form.booking_grand_total,
					payment_type:this.form.payment_type,
					block_date:this.form.block_date,
					paid_amount:this.form.paid_amount,
					add_ons:this.form.booking_addons_total,
					customer_name:this.form.booking_for_name,
				}];
				this.$store.commit("SET_OFFLINE_BOOKING_QUOTATION", offline_booking_quotation);
				this.$router.push("/my-booking/quotation");
			},
			autocalculation(amount)
			{
				
				/* var qty = this.add_ons_list.map(function (obj) { return (obj[1]) });
				var add_amount = this.add_ons_list.map(function (obj) { return (obj[2]) });
				var add_amounts =add_amount*qty; */
				var add_amount=0;
				for(var i=0;i<this.add_ons_list.length;i++)
				{
					add_amount+=parseFloat(this.add_ons_list[i][1]*this.add_ons_list[i][2]);
				}
				this.add_amount=add_amount;

				if(this.add_amount==0)
				{
					this.addontotal=this.childvenue.shift_price;
				}
				else
				{
					this.addontotal=this.add_amount+amount;
				}
				
				
				if(this.form.applied_discount != '' && this.form.applied_discount >= 0 && this.addontotal >= this.form.applied_discount){
					if(this.form.applied_discount >this.childvenue.shift_price )
					{
						this.after_discount = parseInt(this.addontotal);
					}
					else
					{
						this.after_discount = parseInt(this.addontotal) - parseInt(this.form.applied_discount);
						
						//this.check_booking_payment();
					}
					
				}
                else
				{
					this.after_discount = parseInt(this.addontotal);
				}
				if(this.booked_val=='booked')
				{
					if(this.taxRequired=='yes')
					{
						this.taxper =this.gst_perentage;
						this.afterTax=this.after_discount*this.taxper/100;
					}
					else
					{
						this.afterTax=0;
					}
				}
				else if(this.booked_val=='blocked')
				{
					this.afterTax=0;
				}
				else
				{
					this.taxper=0;
					this.afterTax=0;
				}
				/* if(this.booked_val=='blocked')
					{
					this.afterTax=0;
				} */
				
				this.total_amount=this.after_discount+this.afterTax; //inclusive tax
				
				if(this.json_convert(this.childvenue.settings.all_settings).allow_security==true)
				{
					
					this.full_total_amount=Math.round(parseFloat(this.total_amount)+parseFloat(this.json_convert(this.childvenue.settings.all_settings).security_deposit ? this.json_convert(this.childvenue.settings.all_settings).security_deposit : 0 ));
				}
				else
				{
					
					this.full_total_amount=Math.round(this.total_amount);
					
				}
				
				this.check_booking_payment();
				return amount;
			},
			getImage(jimg)
			{
				return  '/img/'+jimg;
			},
			onChange(indx,evt, product,amounts,images,add_on_id,type){
				
				evt.preventDefault();
				var add;var msg;
				//this.countInput[indx]=1;
				var qty = type!='unit' ? 1 : this.countInput[indx];
				if(qty==0 &&  this.check_active_item(add_on_id)=='-1')
				{
					this.$root.$refs.app.showToast("danger", 'Please select Quantity');
					return true;
				} 
				var amount_total =  amounts*qty;
				
				const ar =[ product ,amounts,images,qty,amount_total];
				const add_array =[ add_on_id ,qty ,amounts ,'no'];
				
				//	this.add_ons_list.push(add_ons_list);
				var matchedIndex;
				var matchedIndex1;
				//add_on_id
				if(type!='unit')
				{
					if (evt.target.checked){
					
					this.activeItem.push(
					ar
					);
					
					this.add_ons_list.push(add_array);
					
					//this.add_amount+=parseFloat(amount_total);
					msg="Add-on added";
					add=10;
					} else {
					matchedIndex = this.activeItem.map(function (obj) { return obj[0] }).indexOf(product);
					matchedIndex1 = this.add_ons_list.map(function (obj) { return obj[0] }).indexOf(add_on_id);
					this.activeItem.splice(matchedIndex, 1)
					//this.add_amount-=parseFloat(amount_total);
					
					this.add_ons_list.splice(matchedIndex1,1);
					
					msg="Add-on Removed";
					add=0;
				}
				
				}
				else
				{
					
					matchedIndex = this.activeItem.map(function (obj) { return obj[0] }).indexOf(product);
					
					matchedIndex1 = this.add_ons_list.map(function (obj) { return obj[0] }).indexOf(add_on_id);
				
					if(qty==0 && matchedIndex!=-1)
					{
						
						this.add_ons_list.splice(matchedIndex1,1);
					
					this.activeItem.splice(matchedIndex, 1)
					
					
					

					
					msg="Add-on Removed";
					
					}
					else if(matchedIndex!=-1)
					{
						
						var amt = this.activeItem[matchedIndex][1];
						//var already_amt = this.activeItem[matchedIndex][4];
						var Tamt = this.countInput[indx]*amt;
						this.activeItem[matchedIndex][3]=this.countInput[indx];
						this.activeItem[matchedIndex][4]=Tamt;
						this.activeItem.push();

						this.add_ons_list[matchedIndex1][1]=this.countInput[indx];
						this.add_ons_list.push();


						this.autocalculation(this.childvenue.shift_price);
					}
					else if(matchedIndex==-1)
					{
						
						this.activeItem.push(
					ar
					);
					
					this.add_ons_list.push(add_array);
					
					//this.add_amount+=parseFloat(amount_total);
					msg="Add-on added";
					}
					
				}
				
				this.autocalculation(this.childvenue.shift_price);
				this.$root.$refs.app.showToast("success", msg);
				this.$store.commit("SET_NOTIFICATION", add);
				//this.$router.push({name:"notify"})
			},
			gettimenew()
			{
				
				if (this.form.shift === "" ||(this.form.shift === null && this.form.child_selected === "") ||this.form.child_selected === null)
				{
					this.shift = null;
					
					} else {
					var child_id = this.form.child_selected;
					var shift_type = this.form.shift;
					var booking_date = this.form.start_booking_date;
					
					axios
					.post("/api/new_child_venue_price_listing", {
						shift_type: shift_type,
						child_id: child_id,
						booking_date: booking_date,
					})
					.then((resp) => {
						resp
						this.add_amount=0;
						this.checkShiftsAvailability();
						if(this.fromchecking!="calender")
						{
							this.form.stime=resp.data.message.from_time;
							this.form.etime=resp.data.message.to_time;
							this.form.ostime=resp.data.message.from_time;
							this.form.oetime=resp.data.message.to_time;
						}
						
						
					})
					//alert();
					//this.checkShiftsAvailability();
				}
				
				
			},
			changeShiftId(names)
			{
				if(names=="Morning")
				{
					return 1;
				}
				else if(names=="Afternoon")
				{
					return 2;
				}
				else if(names=="Evening")
				{
					return 3;
				}
			},
			json_convert(attr)
			{
				const v=attr != null ? JSON.parse(attr) :false;
				return v;
			},
			Indian_numberWithCommas(x) {
				return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
			},
			date_format:function(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
				
			
			},
			getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			convert_24_12hour(timeString)
			{
				const [hourString, minute] = timeString.split(":");
				const hour = +hourString % 24;
				return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
			},
			isValidTime(fromtime,totime) 
			{
				console.log(fromtime);
				console.log(totime);
				const time = moment(fromtime, this.format);
				const beforeTime = moment(this.form.ostime, this.format);
				const afterTime = moment(this.form.oetime, this.format);
				
				if (time.isBetween(beforeTime, afterTime))
				{
					console.log("no correct");
					return true;
					
				} 
				else 
				{
					return false;
				}
			},
			isValidTime1(fromtime,totime) 
			{
				console.log(totime);
				console.log(fromtime);
				const time = moment(fromtime, this.format);
				const beforeTime = moment(this.form.ostime, this.format);
				const afterTime = moment(this.form.oetime, this.format);
				if (time.isBetween(beforeTime, afterTime))
				{
					console.log("no correct");
					return true;
					
				} 
				else 
				{
					return false;
				}
			},
			check_booking_payment()
			{
				
				if(this.form.payment_master_type=='blocked')
				{
					var block_amount = this.json_convert(this.childvenue.settings.all_settings).block_amount ? this.json_convert(this.childvenue.settings.all_settings).block_amount : 0;
					/* if(!this.form.paid_amount)
						{
						this.form.paid_amount=block_amount;
					} */
					this.form.paid_amount=Math.round(block_amount);
					this.form.applied_discount_percentage=0;
					this.form.applied_discount=0;
					this.autocalculation(this.childvenue.shift_price)
				}
				else if(this.booked_val=='booked')
				{
					if(this.booked_type=='full')
					{
						this.form.paid_amount=Math.round(this.full_total_amount);
					}
					else if(this.booked_type=='advance')
					{
						if(this.form.paid_amount==this.childvenue.shift_price)
						{
							this.form.paid_amount=Math.round(this.childvenue.shift_price);
						}
						else
						{
							this.form.paid_amount;
						}
					}
					this.autocalculation(this.childvenue.shift_price)
				}
				else
				{
					//this.form.paid_amount=this.full_total_amount;
				}
				
			},
			Checkpay_type()
			{
				if(this.booked_type=='full')
				{
					this.form.paid_amount=Math.round(this.full_total_amount);
				}
				else if(this.booked_type=='advance')
				{
					this.form.paid_amount=Math.round(this.childvenue.shift_price);
				}
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
			CheckPhonenumber(e)
			{
				e
				if(this.form.booking_for_mobile_no!='' && this.form.booking_for_mobile_no == this.form.contact || 
				this.form.contact!='' && this.form.contact == this.form.Acontact || this.form.Acontact!='' && this.form.Acontact== this.form.booking_for_mobile_no
				)
				{
					this.mobile_message="Same Mobile number";
					this.form.Acontact="";
				}
				else
				{
					this.mobile_message="";
				}
			},
			checkamount_validation()
			{
				this.errormessage_amount="";
				if(this.form.paid_amount<0)
				{
					this.errormessage_amount="Please check full amount";
				}
				if(this.form.paid_amount>this.full_total_amount)
				{
                    this.errormessage_amount="Please check full amount";
				}
				
			},
			CheckEventType()
			{
				this.OnlyWedding=false;
				if(this.form.event=="F2B200BB-A57B-29DF-FED9-37D6983291C4")
				{
					this.OnlyWedding=true;
				}
			},
			getImageUrl(img)
			{
				return img;
			},
			checkemail_avaliblity()
			{
				axios
				.post("/api/customer_email_avaliblity", {
					email: this.form.email,
				})
				.then((resp) => {
					resp
					if(resp.data)
					{
						this.form.name=resp.data.name;
						this.form.contact=resp.data.phone;
						this.form.address=resp.data.address;
					}
					
				})
			},
			checkGuestCapcity()
			{
				if(this.form.gustcapa <= this.form.guest_rooms)
				{
					this.form.CustomguestCapacity=false;
				}
				else
				{
					this.form.CustomguestCapacity=true;
				}
				this.suggestion();
			},
			suggestion()
			{
				axios
				.post("/api/booking_suggestion", {
					venue_id: this.parent_venue_id,
					capacity: this.form.gustcapa,
					child: this.form.child_selected,
					start_booking_date: this.form.start_booking_date,
					shift: this.form.shift,
				})
				.then((resp) => {
					this.suggestions = resp.data;
					
					
				})
			},
			checkarray(selected)
			{
				
				var matchedIndex = this.form.selected_add_ons.map(function (obj) { return obj  }).indexOf(selected);
				if(matchedIndex!=-1)
				{
					if(selected==this.form.selected_add_ons[matchedIndex])
					{
						return true;
					}  
					else
					{
						return false;
					}
				}
				else
					{
						return false;
					}
				
			},
			check_active_item(add_id)
			{
				return this.add_ons_list.map(function (obj) { return (obj[0]) }).indexOf(add_id);
			},
				
			access_permission()
			{
				axios
				.post("/api/access_permission_all").then((resp) => {
					for(var i=0; i< resp.data.length;i++)
					{
						if(resp.data[i].subname=='Create Booking' )
						{
							this.permission_access= resp.data[i];
						}
						if(resp.data[i].subname=='Draft Booking' )
						{
							this.permission_access1= resp.data[i];
						}
						
					}
				});
			},
			checked_stocks(add_id)
			{
				return this.form.selected_add_ons.map(function (obj) { return obj }).indexOf(add_id);
			},
			//form.CustomguestCapacity plus_number minus_number  stock_count
			increment(key,max_qty)
			{
				const quantityInput = document.querySelector("#quantity"+key);
				if(quantityInput==null)
				{
					return 0;
				}
				else
				{
					var qtys = parseInt(quantityInput.value) + 1;
				
					quantityInput.value = max_qty<qtys ? max_qty : qtys;
					this.countInput[key]=max_qty<qtys ? max_qty : qtys;
					this.checkInput(key);
				}
			},
			decrement(key)
			{
				const quantityInput = document.querySelector("#quantity"+key);
				if(quantityInput==null)
				{
					return 0;
				}
				else
				{
					var qtys = parseInt(quantityInput.value) - 1;
					quantityInput.value = qtys< 0 ? 0 : qtys;
					this.countInput[key]= qtys< 0 ? 0 : qtys;
					//return qtys ;
					this.checkInput(key);
				}
				
			},
			check_decrement(key)
			{
				alert(this.countInput[key]) ;
			},
			checkInput(key)
			{
				this.autoData = this.countInput[key] ;
			}
			/* callauto(key)
			{
				const quantityInput = document.querySelector("#quantity"+key);
				var qtys = parseInt(quantityInput.value);
				alert(qtys);
			} */
		
			
		},
		mounted() {
			//this.isHeaderSticky = true;
			
			
			this.callChildVenue();
			this.get_event_types();
			this.vendor_check();
			this.getProfile();
			this.access_permission();
			//this.vueOnScroll();
			this.baseUrl=process.env.VUE_APP_APIURL;
			document.documentElement.scrollTop = 0;
			
			window.addEventListener('scroll', () => {
				window.addEventListener('scroll', () => {
					this.scrollY = Math.round(window.scrollY);
				});
				this.headerTop = this.$refs.side.getBoundingClientRect().top;
			});
			
		},
		
		watch: {
			scrollY(newValue) {
				if (newValue > this.headerTop) {
					this.isHeaderSticky = true;
					} else {
					this.isHeaderSticky = false;
				}
			},
		
		
		},
		
		
 
		
		
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
	.scrollDown
	{
	position: fixed;
	top: 62px;
	
	}  
	.row.add-remove-button {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin: 10px 0;
	}
	.row.add-remove-button input {
	height: auto;
	max-width: 340px;
	flex: 1;
	}
	.row.add-remove-button button {
	background: #000;
	text-transform: uppercase;
	font-size: 14px;
	margin-left: 8px;
	}
	.form-group {
	margin: 0;
	}
	@media (max-width: 640px) {
	}
	#pd {
	padding: 10px;
	}
	
	#e_message {
	color: red;
	}
	#v_location {
	border-color: rgba(0, 0, 0, 0.1);
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #4f5467;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e9ecef;
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.custom-sticky-top
	{
	position: fixed;
	top:0%
	}
	
	
	input[type="checkbox"][id^="cb"] {
	display: none;
	}
	
	label {
	padding: 10px;
	display: block;
	position: relative;
	margin: 10px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	}
	
	label::before {
	/*background-color: rgb(40, 241, 14); */
    color: rgb(40, 241, 14);
    content: " ";
    display: block;
    /* border-radius: 50%; */
    /* border: 1px solid grey; */
    /* border-radius: 50%; */
    /* border: 1px solid grey; */
    position: absolute;
	bottom: 1px;
    left: 1px;
    width: 30px;
    /* height: 30px; */
    /* text-align: center; */
    line-height: 30px;
    transition-duration: 0.4s;
    transform: scale(0);
    z-index: 2222;
	}
	
	label img {
	transition-duration: 0.2s;
	transform-origin: 50% 50%;
	}
	
	:checked+label {
	border-color: #ddd;
	}
	
	:checked+label::before {
	/* //content: "✓"; */
	/* background-color: rgb(26, 236, 19); */
    transform: scale(1);
    font-size: 20px;
	}
	:checked+label::before span{
	background-color: rgb(26, 236, 19) !important;
	}
	:checked+label img {
	transform: scale(0.9);
	/* box-shadow: 0 0 5px #333; */
	z-index: -1;
	}
	.custom-amount-summary
	{
	list-style-type: none;
    margin-left: -40px;
	}
	
	.custtom-venue-label
	{
	margin:0 !important;
	padding:0 !important;
	cursor:unset;
	}
	select
	{
	cursor: pointer;
	}
	input
	{
	cursor: auto;
	}
	.custom-label
	{
	margin:6px !important;
	padding:0 !important;
	}
	.producttitle
	{
	font-size: 11px;
	margin-top: -42px;
	}
	.pricetext
	{
	font-size: 13px;
	padding: 6px;
	font-weight: 700;
	}
	.shadow-1
	{
	box-shadow: 1px 2px 1px 2px;
	}
	.product-images img {
    height: 100px;
    border-radius: 15px;
    max-height: 210px;
    -o-object-fit: cover;
    object-fit: cover;
    /* min-height: 210px; */
    object-fit: cover;
	}
	.product-boxs {
    border-radius: 20px;
    width: 50%;
    background: var(--white);
    padding: 8px;
    position: relative;
    border: 1px solid var(--white);
    transition: all 0.2s linear;
	}
	/*  Ribbons */
	.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: center;
	}
	
    .ribbon span {
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	line-height: 20px;
	transform: rotate(45deg);
	width: 100px;
	display: block;
	background: #F62459;
	background: linear-gradient(#ff89b1 0%, #ff006e 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	right: -21px;
	
    }
	
	.ribbon span::before {
	content: '';
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #F62459;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #F62459;
	}
	
	.ribbon span::after {
	content: '';
	position: absolute;
	right: 0%;
	top: 100%;
	z-index: -1;
	border-right: 3px solid #F62459;
	border-left: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #F62459;
	}
	
	
	#inventory .card:hover {
    transition: all 0.3s ease;
    box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
	}
	
	
	#inventory .view-btn {
    background-color: #e6de08;
    margin: -25px 0 0 0;
    border-radius: 0 0 0 0;
    font-size: 14px;
    border: #e6de08;
    color: #000;
	
	}
	
	
    #inventory .btn:hover {
	background-color: #ff4444;
	color: #fff;
	border: 2px solid #ff4444;
	transition: all 0.3s ease;
	box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
    }
	.fs-12
	{
	font-size: 12px;
	}
	.fs-11
	{
	font-size: 11px;
	}
	.extradesign
	{
	padding: 4px 4px 2px;
    box-shadow: 0px 0px 5px 0px;
    border-radius: 7px;
	/* height: 50px; */
	margin-bottom: 11px;
	}
	.addon-label
	{
	padding: unset;
	margin: unset;
	}
	
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	
	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}
	.underline_color
	{
	border:2px solid #A44BF3;
	}
	
	.is-valid
	{
	background:unset !important;
	padding-right: 0 !important;
	}
	.bg-custom-gray
	{
	background: #F5F5F5;
	}
	.disabledcard
	{
	pointer-events: none;
	background: #e5e5e5;
	}
	.b-form-btn-label-control.form-control > .form-control
	{
	color:black !important;
	}
	.width-fixed
	{
	width: 20% !important;
	}
	.fix-set
	{
	object-fit: cover;
    height: 120px;
	}
	.back-color
	{
	background-color: #F5F5F5 !important;
	}
	
	
	.radio {
	padding: 4px;
	position: relative;
	}
	
	.radio input {
	width: auto;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	cursor: pointer;
	border-radius: 2px;
	padding: 4px 8px;
	color: #bdbdbdbd;
	font-size: 14px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	transition: all 100ms linear;
	}
	
	.radio input:checked {
	background-image: linear-gradient(180deg, #95d891, #74bbad);
	color: #fff;
	box-shadow: 0 1px 1px #0000002e;
	text-shadow: 0 1px 0px #79485f7a;
	}
	
	.radio input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
	}
	
	.side-container--sticky  {
	
	position: fixed;
	top: 75px;
	width: calc(28%);
	overflow: hidden;
	overflow-y: scroll;
	height: calc(100vh - 100px);
    }
	.disabled
	{
	pointer-events:none;

	}
	
</style>
