// src/mixins/clickMixin.js create global js file for shift-timing from-to date/time
export default {
  methods: {
    setShiftTime(starttime,endtime){
      starttime = parseInt(starttime);
      endtime = parseInt(endtime);
      var timelist = [];
      var time_1,val = '';
      for(time_1=starttime; time_1<=endtime; time_1++){
        if(time_1 == 24){
          val = this.leadingZero(0) + ':00:00';
        }else{
          val = this.leadingZero(time_1) + ':00:00';
        }
        timelist.push({
          value: val, text: this.convertTime24to12(time_1)
          //value:14:00:00 text:02:00 PM
        });
      }
      return timelist;
    },
    leadingZero(day)
    {
      //this function will add 0 add pre for 1 to 9 day/month
      if (day < 10) {
        day = '0' + day;
      }
      return day;
    },
    convertTime24to12(hours){
      if(hours == 24){
        hours = 0;
      }
      let minutes = 0;    
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours || 12;    
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      const strTime = `${hours}:${minutes} ${ampm}`;
      return strTime;
    },
    convertTime12to24(time12h){
      //convert 14:00:00 to 2 and 00:00:00 to 24
      let [hours] = time12h.split(":");
      hours = parseInt(hours);
      if(hours == 0){
        hours = 24;
      }
      return `${hours}`;
    },
  }
};